<template>
  <div class="tab-bar">
    <router-link :to="'/'" replace class="tab-bar-items">
      <img src="../../public/image/indexicon.png" alt="" style="width:23px;height:22px"/>
      <span>首页</span>
    </router-link>
    <router-link :to="'/pay'" class='tab-bar-item'>
      <img src="../../public/image/button.svg" alt="" style="width:168px;height:56px" />
    </router-link>
    <router-link :to="'/me'" replace class="tab-bar-items">
      <img src="../../public/image/meicon.png" alt="" style="width:22px;height:22px" />
      <span>我的</span>
    </router-link>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods:{
    
  },
  mounted(){
  
  }
};
</script>
<style scoped>
.tab-bar {
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 82px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px -2px 12px 0px #f5f5f5;
  border-radius: 10px 10px 0px 0px;
}
.tab-bar-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
}
.tab-bar-items img {
  width: 23px;
  height: 23px;
}
.tab-bar-items span {
  font-size: 11px;
  font-weight: 400;
  color: #333333;
  margin-top: 3px;
}
.tab-bar-item{
  width: 168px;
  height: 56px;
}
</style>