import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import Vant from 'vant'
import 'vant/lib/index.css'
import Mint from 'mint-ui'
import 'mint-ui/lib/style.min.css'
import 'lib-flexible/flexible'
import '@vant/touch-emulator';
Vue.use(Mint)
import { Dialog } from 'vant';
Vue.prototype.axios = axios
Vue.use(Vant);
// axios.defaults.baseURL = "https://api.luyopay.com/api"
axios.defaults.baseURL = "https://api.deer.funengyun.cn/api"
axios.defaults.timeout = 30000;//设置超时时间
axios.interceptors.request.use(
  config => {
    let reg = /\d+/g;
    let url = window.location.href
    let shop = url.split("shop_id=")[1];
    if (shop) {
      if (shop.match(reg)[0] != localStorage.getItem('shop')) {
        localStorage.clear()
      }
    }
    if (localStorage.getItem('token')) {
      config.headers.token = localStorage.getItem('token');//把localStorage的token放在Authorization里
      config.headers.shop = localStorage.getItem('shop')
    }
    return config;

  },
  function (err) {
    console.log("失败信息" + err);
  }
);

axios.interceptors.response.use(
  response => {
    let url = window.location.href;
    let user = url.split("corp_user_id=")[1];
    let id = url.split("&id=")[1];
    let type = url.split("type=")[1];
    let grant_user_id = url.split("grant_user_id=")[1];
    let reg = /\d+/g;
    if (user) {
      sessionStorage.setItem("user", user.match(reg)[0]);
    }
    if (id) {
      localStorage.setItem("id", id.match(reg)[0]);
      localStorage.setItem("type", type.match(reg)[0]);
      localStorage.setItem("grant_user_id", grant_user_id.match(reg)[0]);
    }
    if (response.data.code == -3) {
      Dialog.confirm({
        title: '提示',
        message: '你还不是本店会员，请先注册',
      })
        .then(() => {
          router.push('/register')
        })
        .catch(() => {
          // on cancel
        });
    } else if (response.data.code >= 0) {
      return response
    } else if (response.data.code == -1 || response.data.code == -2) {
      let url = window.location.href;
      let shop = url.split("shop_id=")[1];
      var dz_url = url.split("code=")[1];
      let id = url.split("&id=")[1];
      let type = url.split("type=")[1];
      let grant_user_id = url.split("grant_user_id=")[1];
      let reg = /\d+/g;
      if (id) {
        localStorage.setItem("id", id.match(reg)[0]);
        localStorage.setItem("type", type.match(reg)[0]);
        localStorage.setItem("grant_user_id", grant_user_id.match(reg)[0]);
      }
      if (shop) {
        localStorage.setItem("shop", shop.match(reg)[0]);
      }
      var code;
      if (dz_url) {
        code = dz_url.split("&")[0];
      }
      console.log(code, 8888);
      if (code != undefined) {
        if (sessionStorage.getItem("user")) {
          axios({
            method: "post",
            url: "/officialAccount/user/login",
            data: {
              code: code,
              shop_id: localStorage.getItem("shop"),
              corp_user_id: sessionStorage.getItem("user"),
            },
          }).then((result) => {
            console.log(result);
            localStorage.setItem("token", result.data.data);
            console.log(localStorage.getItem("token"), 222);
            console.log(window.location.href.split('#')[1])
            location.href = window.location.href.split('?')[0] + '#' + window.location.href.split('#')[1]
          });
        } else {
          axios({
            method: "post",
            url: "/officialAccount/user/login",
            data: {
              code: code,
              shop_id: localStorage.getItem("shop"),
            },
          }).then((result) => {
            console.log(result);
            localStorage.setItem("token", result.data.data);
            console.log(window.location.href.split('#')[1])
            location.href = window.location.href.split('?')[0] + '#' + window.location.href.split('#')[1]
          });
        }

      } else {
        axios({
          method: "post",
          url: "/officialAccount/user/getRedirectUrl",
          data: { url: location.href }
        }).then((res) => {
          window.location.href = res.data.data;
        });
      }
    }
  },

  error => {
    // alert('请求出错le' + error.message || '未知错误')
    // return new Promise(() => { })
    console.log(error)
  }
)

Vue.config.productionTip = false
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
