<template>
  <div class="detail">
    <div class="title">积分明细</div>
    <div v-for="(p, i) of this.n1" :key="i">
      <div :class="p > 0 ? 'item' : 'item1'">
        <span>2021.07.03</span>
        <span>消费买单 {{ p }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      n: [231, 192, -4000, -3200],
      n1: [],
    };
  },
  mounted() {
    for (let p of this.n) {
      if (p > 0) {
        this.n1.push("+" + p);
      } else if (p < 0) {
        this.n1.push(p);
      }
    }
    console.log(this.n1);
  },
};
</script>
<style scoped>
.detail {
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px #f5f5f4;
  border-radius: 10px 10px 0px 0px;
  min-height: 100vh;
  padding: 17px 12px;
}
.title {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  margin-bottom: 10px;
}
.item {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  font-weight: 400;
  color: #333333;
  margin-bottom: 5px;
  background: #fff8f0;
  border-radius: 4px;
  padding: 10px 12px;
}
.item1 {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  font-weight: 400;
  color: #333333;
  margin-bottom: 5px;
  background: #f2fbf3;
  border-radius: 4px;
  padding: 10px 12px;
}
</style>