<template>
  <div style="height: 100vh">
    <div class="content"></div>
    <div class="footer">
      <div class="one">
        <div class="item" v-for="(p,i) of item" :key="i">
          <img :src=p.img alt="" />
          <span>{{p.title}}</span>
        </div>
      </div>
      <div class="two">
        <img src="../../public/image/yuyin.png" alt="">
        <input type="text">
        <img src="../../public/image/biaoqing.png" alt="">
        <img src="../../public/image/jia.png" alt="">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return{
      item:[
        {img:require("../../public/image/give-icon.png"),title:"送优惠卷"},
        {img:require("../../public/image/tc-icon.png"),title:"推送套餐"},
        {img:require("../../public/image/define-icon.png"),title:"客户画像"},
        {img:require("../../public/image/toll-icon.png"),title:"运营工具"},
      ]
    }
  }
};
</script>
<style scoped>
.content {
  height: 86%;
  background-color: #eff3f8;
}
.footer {
  height: 94px;
  background-color: #f5f6f6;
}
.one {
  padding: 10px 10px 10px 20px;
  display: flex;
  overflow: auto;
  align-items: center;
}
.item {
  background: #ffffff;
  border-radius: 14px;
  display: flex; 
  justify-content: space-around;
  align-items: center;
  width: 90px;
  height: 28px;
  margin-right: 10px;
  padding: 3px 4px;
  flex: none;
}
.item span {
  margin-left: 5px;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
}
.item img{
  width: 16px;height: 16px;
}
.two{
  padding: 5px 10px 10px 10px;
  display: flex;
  align-items: center;
  overflow: auto;
}
.two img{
  width: 28px;height: 28px;
}
.two img:first-child{
  margin-right: 15px;
}
.two input{
  border: none;
  background-color: #fff;
  height: 30px;
  margin-right: 15px;
  width: 230px;
  border-radius: 5px;
  padding: 0 5px;
}
.two img:last-child{
  margin-left: 10px;
}
</style>