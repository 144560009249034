<template>
  <div>
    <div style="padding-bottom: 222px">
      <div class="header">
        <img
          :src="shop.logo"
          alt=""
          style="width: 42px; height: 42px; border-radius: 50px"
        />
        <img
          src="../../public/image/zi.png"
          alt=""
          style="width: 151px; height: 20px; border-radius: 50px"
        />
      </div>
      <div class="content">
        <div class="a1">
          <span>消费原价</span>
          <!-- <div>
            <span
              :style="{
                color:
                  value == '请询问服务员后输入' || value == ''
                    ? '#d8d8d8'
                    : '#333',
                fontWeight: 600,
                fontsize: 14,
              }"
              style="text-align: end"
              >{{ value == "" ? "请询问服务员后输入" : value }}</span
            >
          </div> -->
          <van-field
            readonly
            clickable
            :value="value"
            style="
              width: 160px;
              padding-left: 0;
              padding-right: 0;
              font-size: 18px;
              font-weight: 500
            "
            :bind-hasFocus="true"
            placeholder="请询问服务员后输入"
            :border="false"
            v-model="value"
            @touchstart.native.stop="
              show = true;
              show1 = false;
            "
            input-align="right"
          />
          <van-number-keyboard
            :show="show"
            theme="custom"
            extra-key="."
            close-button-text="确定买单"
            style="padding: 0"
            v-model="value"
            @input="onInput"
            @close="onClose"
          />
        </div>
        <div class="a2">
          <div>
            <div style="display: flex; align-items: center">
              <span>不参与优惠金额（酒水、特价套餐）</span>
              <van-switch
                v-model="checked1"
                active-color="#fe4c0f"
                inactive-color="#dcdee0"
                size="18px"
                @click="check"
              />
            </div>
            <van-field
              readonly
              clickable
              :value="value1"
              style="
                width: 60px;
                padding: 0;
                font-weight: bold;
                font-size: 18px;
                font-weight: 500
              "
              :bind-hasFocus="true"
              placeholder="请输入"
              :border="false"
              @touchstart.native.stop="
                show1 = true;
                show = false;
              "
              v-show="checked1"
              input-align="right"
            />
            <van-number-keyboard
              :show="show1"
              theme="custom"
              extra-key="."
              close-button-text="确定买单"
              style="padding: 0"
              v-model="value1"
              @input="onInput1"
              @close="onClose"
            />
          </div>
        </div>
        <div class="a3">
          <div>
            <div class="a3-left">
              <img src="../../public/image/yhj-icon.png" />
              <router-link :to="{ path: '/mecoupon', query: { type: 1 } }"
                >优惠券(已选{{ num }}张)</router-link
              >
            </div>
            <div class="right1">
              <span>-¥{{ discount_money }}</span>
              <img
                src="../../public/image/wxz.png"
                :style="state2 ? 'display:none' : ''"
              />
              <img
                src="../../public/image/xz.png"
                :style="!state2 ? 'display:none' : ''"
                @click="state2 = false"
              />
            </div>
          </div>
        </div>
        <div class="a4">
          <div class="a4-item">
            <div
              class="qp"
              v-show="value != '请询问服务员后输入' && value != ''"
              style="display: none"
            >
              本次消费积分+{{ value }}分
            </div>
            <div class="a4-pay">
              <span>小计：</span>
              <span v-if="state2"
                >{{
                  value == "请询问服务员后输入" || value == ""
                    ? "0.00"
                    : (value - discount_money).toFixed(2)
                }}</span
              >
              <span v-else
                >{{
                  value == "请询问服务员后输入" || value == "" ? "0.00" : value
                }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <van-popup v-model="showPay" style="border-radius: 10px">
        <div class="footer">
          <div class="footer-title">
            <span>选择支付方式</span>
            <img
              src="../../public/image/close-icon@2x.png"
              alt=""
              style="width: 14px; height: 14px; margin-left: 75px"
              @click="showPay = false"
            />
          </div>
          <van-cell-group>
            <van-cell clickable @click="pay(1)" class="cell-item">
              <template #right-icon>
                <div class="cell-left">
                  <img src="../../public/image/wx-icon@2x.png" alt="" />
                  <div>微信支付</div>
                </div>
              </template>
            </van-cell>
            <van-cell clickable @click="pay(2)" class="cell-item">
              <template #right-icon>
                <div class="cell-left">
                  <img src="../../public/image/yeicon@2x.png" alt="" />
                  <div>
                    余额支付
                    <span v-if="user"
                      >(可用余额：{{ user.wallet_amount }})</span
                    >
                  </div>
                </div>
              </template>
            </van-cell>
            <van-cell clickable @click="pay(0)" class="cell-item">
              <template #right-icon>
                <div class="cell-left">
                  <img src="../../public/image/cashicon@2x.png" alt="" />
                  <div>现金支付</div>
                </div>
              </template>
            </van-cell>
            <van-cell clickable @click="pay(3)" class="cell-item">
              <template #right-icon>
                <div class="cell-left">
                  <img src="../../public/image/POSicon@2x.png" alt="" />
                  <div>POS支付</div>
                </div>
              </template>
            </van-cell>
          </van-cell-group>
        </div>
      </van-popup>
      <van-overlay :show="overlay1" class="vant-overlay">
        <div class="wrapper">
          <div class="block1">
            <div class="top">
              <img :src="src" alt="" />
              <div>该操作需店长扫码确认后继续</div>
            </div>
            <div class="foot" @click="overlay1 = false">取消</div>
          </div>
        </div>
      </van-overlay>
      <van-overlay :show="overlay" class="vant-overlay">
        <div class="wrapper">
          <div class="block">
            <div class="top">
              <img src="../../public/image/yes.png" alt="" />
              <div class="title">支付成功</div>
              <div class="details" v-if="level.length != 0">
                <div style="text-align: center; margin-top: 5px">
                  本次买单后余额已低于***元
                </div>
                <div style="text-align: center">您将丧失股东权限</div>
              </div>
            </div>
            <div
              class="foot"
              @click="
                overlay = false;
                goIndex();
              "
            >
              确定
            </div>
            <router-link :to="'/topup'" style="display: none">
              <div class="foot">去储值保持股东权限</div>
            </router-link>
          </div>
        </div>
      </van-overlay>
    </div>
    <div class="goindx" @click="goindex()">首页</div>
  </div>
</template>
<script>
import { Dialog } from "vant";
import { Toast } from "vant";
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      show1: false,
      show: true,
      value: "",
      value1: "",
      checked1: true,
      state1: false,
      state2: false,
      overlay1: false,
      overlay: false,
      shop: "",
      user: "",
      radio: "",
      coupons_id: "",
      discount_money: 0,
      num: 0,
      showPay: false,
      level: [],
      total_money: 0,
    };
  },
  beforeRouteEnter(to, from, next) {
    if (from.path == "/mecoupon") {
      to.meta.isBack = true;
    } else {
      to.meta.isBack = false;
    }
    next();
  },
  methods: {
    goindex() {
      this.$router.push("/");
    },
    check() {
      this.checked1 = false;
      this.value1 = "";
    },
    getlevel() {
      this.axios({
        url: "/officialAccount/user/level",
        method: "post",
      }).then((res) => {
        this.level = res.data.data;
      });
    },
    goIndex() {
      if (window.location.href.indexOf("order_id=") != -1) {
        window.location.href = window.location.href.split("order_id")[0] + "#/";
      } else {
        this.$router.push("/");
      }
    },
    onInput(value) {
      console.log(value);
      if (this.value == "" || this.value == ".") {
        console.log(value);
        if (value != ".") {
          this.value += value;
        }
      } else if (this.value * 1 < 100000 && this.value.length < 6) {
        if (this.value.indexOf(".") != -1) {
          if (this.value.split(".")[1].length < 2 && value != ".") {
            this.value += value;
          }
        } else {
          this.value += value;
        }
      } else if (this.value == "" || this.value == ".") {
        console.log(value);
        if (value != ".") {
          this.value += value;
        }
      }
    },
    onInput1(value) {
      console.log(value);
      if (this.value1 == "" || this.value1 == ".") {
        console.log(value);
        if (value != ".") {
          this.value1 += value;
        }
      } else if (this.value1 * 1 < 100000 && this.value1.length < 6) {
        if (this.value1.indexOf(".") != -1) {
          if (this.value1.split(".")[1].length < 2 && value != ".") {
            this.value1 += value;
          }
        } else {
          this.value1 += value;
        }
      } else if (this.value1 == "" || this.value1 == ".") {
        console.log(value);
        if (value != ".") {
          this.value1 += value;
        }
      }
    },
    onDelete() {
      console.log(this.value);
      if (this.value != "请询问服务员后输入") {
        this.value = this.value.substr(0, this.value.length - 1);
      }
    },

    pay(radio) {
      if (
        this.value <= 0 ||
        this.value == "" ||
        this.value == "请询问服务员后输入"
      ) {
        Toast.fail("金额不能为空");
      } else {
        Toast.loading("正在支付");
        this.showPay = false;
        if (sessionStorage.getItem("user")) {
          if (this.state2) {
            this.axios({
              method: "post",
              url: "/officialAccount/order/checkoutOrder",
              data: {
                price: this.value * 1,
                discount_price: this.value * 1 - this.discount_money * 1,
                type: radio,
                corp_user_id: sessionStorage.getItem("user"),
                notify_url: window.location.href,
                coupons_id: this.coupons_id * 1,
                no_discount_price: this.value1 * 1,
              },
            }).then((res) => {
              console.log(res);
              Toast.clear();
              if (res.data.data) {
                if (radio == 0 || radio == 3) {
                  console.log(res.data.data.order_no);
                  this.src = res.data.data.json;
                  this.overlay1 = true;
                  this.checkOrderStatus(res.data.data.order_no, 50);
                } else if (radio == 1) {
                  window.WeixinJSBridge.invoke(
                    "getBrandWCPayRequest",
                    res.data.data.json,
                    function (res) {
                      if (res.err_msg == "get_brand_wcpay_request:ok") {
                      }
                    }
                  );
                }
              } else if (res.data.message == "成功") {
                this.overlay = true;
              } else if (res.data.message == "钱包余额不足") {
                Toast.fail("钱包余额不足");
              }
            });
          } else {
            this.axios({
              method: "post",
              url: "/officialAccount/order/checkoutOrder",
              data: {
                price: this.value * 1,
                discount_price: this.value * 1 - this.discount_money * 1,
                type: radio,
                corp_user_id: sessionStorage.getItem("user"),
                notify_url: window.location.href,
                no_discount_price: 0,
              },
            }).then((res) => {
              Toast.clear();
              console.log(res);
              if (res.data.data) {
                if (radio == 0) {
                  console.log(res.data.data.order_no);
                  this.src = res.data.data.json;
                  this.overlay1 = true;
                  this.checkOrderStatus(res.data.data.order_no, 50);
                } else if (radio == 1) {
                  window.WeixinJSBridge.invoke(
                    "getBrandWCPayRequest",
                    res.data.data.json,
                    function (res) {
                      if (res.err_msg == "get_brand_wcpay_request:ok") {
                        // 使用以上方式判断前端返回,微信团队郑重提示：
                        //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                      }
                    }
                  );
                }
              } else if (res.data.message == "成功") {
                this.overlay = true;
              } else if (res.data.message == "钱包余额不足") {
                Toast.fail("钱包余额不足");
              }
            });
          }
        } else {
          if (this.state2) {
            this.axios({
              method: "post",
              url: "/officialAccount/order/checkoutOrder",
              data: {
                price: this.value * 1,
                discount_price: this.value * 1 - this.discount_money * 1,
                type: radio,
                notify_url: window.location.href,
                coupons_id: this.coupons_id * 1,
                no_discount_price: this.value1 * 1,
              },
            }).then((res) => {
              console.log(res);
              Toast.clear();
              if (res.data.data) {
                if (radio == 0) {
                  console.log(res.data.data.order_no);
                  this.src = res.data.data.json;
                  this.overlay1 = true;
                  this.checkOrderStatus(res.data.data.order_no, 50);
                } else if (radio == 1) {
                  window.WeixinJSBridge.invoke(
                    "getBrandWCPayRequest",
                    res.data.data.json,
                    function (res) {
                      if (res.err_msg == "get_brand_wcpay_request:ok") {
                        // 使用以上方式判断前端返回,微信团队郑重提示：
                        //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                      }
                    }
                  );
                }
              } else if (res.data.message == "成功") {
                this.overlay = true;
              } else if (res.data.message == "钱包余额不足") {
                Toast.fail("钱包余额不足");
              }
            });
          } else {
            this.axios({
              method: "post",
              url: "/officialAccount/order/checkoutOrder",
              data: {
                price: this.value * 1,
                discount_price: this.value * 1 - this.discount_money * 1,
                type: radio,
                notify_url: window.location.href,
                no_discount_price: 0,
              },
            }).then((res) => {
              Toast.clear();
              console.log(res);
              if (res.data.data) {
                if (radio == 0) {
                  console.log(res.data.data.order_no);
                  this.src = res.data.data.json;
                  this.overlay1 = true;
                  this.checkOrderStatus(res.data.data.order_no, 50);
                } else if (radio == 1) {
                  window.WeixinJSBridge.invoke(
                    "getBrandWCPayRequest",
                    res.data.data.json,
                    function (res) {
                      if (res.err_msg == "get_brand_wcpay_request:ok") {
                        // 使用以上方式判断前端返回,微信团队郑重提示：
                        //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                      }
                    }
                  );
                }
              } else if (res.data.message == "成功") {
                this.overlay = true;
              } else if (res.data.message == "钱包余额不足") {
                Toast.fail("钱包余额不足");
              }
            });
          }
        }
      }
    },
    onClose() {
      console.log(this.total_money * 1, this.value * 1);
      let reg = /^([1-9]\d*(\.\d{1,2})?|([0](\.([0][1-9]|[1-9]\d{0,1}))))$/;
      if (this.value1 == "") {
        if (
          this.value <= 0 ||
          this.value == "" ||
          this.value == "请询问服务员后输入"
        ) {
          Toast.fail("金额不能为空");
        } else if (!reg.test(this.value) || this.value > 100000) {
          Toast.fail("输入的金额格式错误");
        } else if (this.state2 && this.total_money * 1 > this.value * 1) {
          Toast.fail("该优惠券未满足条件");
        } else {
          this.showPay = true;
        }
      } else {
        if (
          this.value <= 0 ||
          this.value == "" ||
          this.value == "请询问服务员后输入"
        ) {
          Toast.fail("金额不能为空");
        } else if (
          !reg.test(this.value) ||
          this.value > 100000 ||
          !reg.test(this.value1)
        ) {
          Toast.fail("输入的金额格式错误");
        } else if (
          this.state2 &&
          this.total_money * 1 > this.value * 1 - this.value1 * 1
        ) {
          Toast.fail("该优惠券未满足条件");
        } else {
          this.showPay = true;
        }
      }
    },
    checkOrderStatus(order_no, times) {
      let that = this;
      if (this.overlay1) {
        this.axios({
          method: "post",
          url: "/officialAccount/order/checkoutOrderVerification",
          data: {
            order_no: order_no,
          },
        }).then((res) => {
          console.log(res);
          if (res.data.code !== 0) {
            if (times > 0) {
              times--;
              setTimeout(() => {
                that.checkOrderStatus(order_no, times);
              }, 3000);
            } else {
              Toast.fail("支付失败");
            }
          } else {
            that.overlay = true;
            that.overlay1 = false;
          }
        });
      }
    },
    checkOrderStatus1(order_no, times) {
      let that = this;
      this.axios({
        method: "post",
        url: "/officialAccount/order/checkoutOrderVerification",
        data: {
          order_no: order_no,
        },
      }).then((res) => {
        console.log(res);
        if (res.data.code !== 0) {
          if (times > 0) {
            times--;
            setTimeout(() => {
              that.checkOrderStatus(order_no, times);
            }, 3000);
          } else {
            Toast.fail("支付失败");
          }
        } else {
          that.overlay = true;
          that.overlay1 = false;
        }
      });
    },
    getshop() {
      this.axios({
        method: "post",
        url: "/officialAccount/index/index",
      }).then((res) => {
        this.shop = res.data.data.shop;
        this.$store.state.shop = res.data.data.shop;
      });
    },
    getuser() {
      this.axios({
        method: "post",
        url: "/officialAccount/user/index",
      }).then((res) => {
        this.user = res.data.data;
        this.$store.state.user = res.data.data;
      });
    },
    getLogin() {
      var url = window.location.href;
      var dz_url = url.split("code=")[1];
      var code;
      if (dz_url) {
        code = dz_url.split("&")[0];
      }
      console.log(code, 8888);
      if (code != undefined) {
        this.axios({
          method: "post",
          url: "/officialAccount/user/login",
          data: {
            code: code,
            shop_id: localStorage.getItem("shop"),
            corp_user_id: sessionStorage.getItem("user"),
          },
        }).then((result) => {
          console.log(result);
          localStorage.setItem("token", result.data.data);
          console.log(localStorage.getItem("token"), 222);
          location.href = location.href.split("?")[0];
          this.axios({
            method: "post",
            url: "/officialAccount/index/index",
          }).then((res) => {
            this.shop = res.data.data.shop;
            this.$store.state.shop = res.data.data.shop;
            document.title = this.shop.name;
          });
          this.axios({
            method: "post",
            url: "/officialAccount/user/index",
          }).then((res) => {
            this.data = res.data.data;
            this.$store.state.user = res.data.data;
          });
          this.getsdk();
          this.onMenuShare();
        });
      } else {
        this.axios({
          method: "post",
          url: "/officialAccount/user/getRedirectUrl",
        }).then((res) => {
          window.location.href = res.data.data;
        });
      }
    },
    getback() {
      let url = window.location.href;
      if (url.indexOf("order_id=") != -1) {
        let order_id = url.split("order_id=")[1].split("#")[0];
        this.checkOrderStatus1(order_id, 20);
      }
    },
  },
  mounted() {
    
    document.title = this.$store.state.title;
    this.getback();
    if (this.shop) {
      console.log(this.shop);
    } else {
      this.getshop();
    }
    if (this.user) {
      console.log(this.user);
    } else {
      this.getuser();
    }
    this.coupons_id = this.$route.query.coupons_id;
    console.log(this.coupons_id);
    if (this.coupons_id != undefined) {
      this.state2 = true;
      this.num = 1;
      this.discount_money = this.$route.query.discount_money;
      this.total_money = this.$route.query.total_money;
      console.log(this.discount_money, this.total_money);
    }
  },
  activated() {
    this.coupons_id = this.$route.query.coupons_id;
    console.log(this.coupons_id);
    if (this.coupons_id != undefined) {
      this.state2 = true;
      this.num = 1;
      this.discount_money = this.$route.query.discount_money;
      this.total_money = this.$route.query.total_money;
    }
  },
};
</script>
<style scoped>
.header {
  height: 74px;
  background: linear-gradient(90deg, #ff8300 0%, #fe4d0f 100%);
  border-radius: 10px 10px 0px 0px;
  align-items: center;
  display: flex;
  box-sizing: border-box;
  padding-left: 20px;
  text-align: center;
}
.header img:nth-child(2) {
  margin-left: 10px;
}

.content {
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 2px 12px 0px #f5f5f4;
  border-radius: 10px 10px 0px 0px;
  margin-top: -10px;
}

.a1 {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.a1 span {
  height: 24px;
  font-size: 17px;
  font-weight: 500;
  color: #333333;
  line-height: 24px;
  padding: 15px 0;
}

.a1 div span {
  height: 24px;
  font-size: 17px;
  font-weight: 300;
  color: #cccccc;
  line-height: 24px;
  display: inline-block;
}

.a2 {
  padding: 0 10px;
}
.a2 > div {
  border-bottom: 1px solid #ebebeb;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
}

.a2 span {
  height: 18px;
  font-size: 13px;
  font-weight: 400;
  color: #666666;
  line-height: 18px;
}

.a2 switch {
  transform: scale(0.6);
  margin-left: -15px;
}

.a3 {
  padding: 0 10px;
}
.a3 > div {
  border-bottom: 1px solid #ebebeb;
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.a3-left {
  display: flex;
  align-items: center;
}

.a3-left img {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.a3-left a {
  width: 106px;
  height: 24px;
  border-radius: 3px;
  border: 1px solid #fe4c0f;
  font-size: 12px;
  font-weight: 400;
  color: #fe4c0f;
  line-height: 24px;
  text-align: center;
}

.a3 .right1 {
  display: flex;
  align-items: center;
}

.a3 .right1 span {
  font-size: 14px;

  font-weight: 600;
  color: #fe4c0f;
  display: inline-block;
  text-align: center;
}

.a3 .right1 img {
  width: 22px;
  height: 22px;
  display: inline-block;
  margin-left: 10px;
}

.a4 {
  padding: 15px 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
}
.a4-item {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
}
.a4-pay span:nth-child(1) {
  width: 28px;
  height: 20px;
  font-size: 22px;
  font-weight: 600;
  color: #666666;
  line-height: 20px;
}

.a4-pay span:nth-child(2) {
  font-size: 22px;
  font-weight: 600;
  color: #333333;
  line-height: 20px;
  margin-left: 5px;
}
.qp {
  min-width: 132px;
  height: 31px;
  background-repeat: no-repeat;
  background-size: 100% 31px;
  background-image: url("../../public/image/qpk.png");
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 400;
  color: #fe4c0f;
  line-height: 31px;
  text-align: center;
  padding: 0 10px;
  display: none;
}
.footer {
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px #f5f5f4;
  border-radius: 10px;
  padding: 0 12px;
  width: 311px;
  border-radius: 10px;
}
.footer-title {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
}
.cell-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  height: 65px;
}
.cell-left {
  display: flex;
  align-items: center;
  padding: 10px 0;
}
.cell-left img {
  width: 18px;
  height: 18px;
  margin-right: 20px;
}
.cell-left div {
  font-size: 16px;
  font-weight: 400;
  color: #323233;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 82%;
  height: 208px;
  background-color: #fff;
  border-radius: 10px;
}
.block1 {
  width: 82%;
  height: 286px;
  background-color: #fff;
  border-radius: 10px;
}
.block .top {
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.block1 .top {
  height: 238px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.block1 .top img {
  width: 160px;
  height: 160px;
  margin-bottom: 10px;
}
.block .top img {
  margin-bottom: 10px;
}
.block .top .title,
.block1 .top div .title {
  font-size: 16px;
  font-weight: 400;
  color: #323233;
}
.block .top .details,
.block1 .top .details {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
}
.block .foot,
.block1 .foot {
  height: 48px;
  text-align: center;
  line-height: 48px;
  font-size: 16px;
  font-weight: 400;
  color: #576b95;
  border-top: 1px solid #ebedf0;
}
.vant-overlay {
  z-index: 101;
}
.content /deep/ .van-key--blue {
  background: linear-gradient(180deg, #ff8201 0%, #fe4d10 100%);
}
.goindx {
  position: fixed;
  right: 5px;
  bottom: 235px;
  width: 60px;
  height: 30px;
  color: #ebedf0;
  border-radius: 13px;
  text-align: center;
  line-height: 30px;
  background: linear-gradient(180deg, #ff8201 0%, #fe4d10 100%);
}
</style>