<template>
  <div>
    <div v-if="data.length != 0">
      <div class="give">
        <img src="../../public/image/yhbg@2x (1).png" alt="" />
      </div>
      <div class="list">
        <div class="item" v-for="(item, index) of data" :key="index">
          <img src="../../public/image/coupon.png" />
          <div class="price" v-if="item.type == 1">
            ¥{{ item.discount_money }}
          </div>
          <div class="price1" v-if="item.type == 2">抵用券</div>
          <div class="line"></div>
          <div class="title" v-if="item.type == 1">
            满{{ item.total_money }}减{{ item.discount_money }}元
          </div>
          <div class="title" v-if="item.type == 2">{{ item.name }}</div>
          <div class="details" v-if="item.type == 1">
            {{ item.description }}
          </div>
          <div class="btn" @click="give(item)">赠送</div>
        </div>
        <div class="footer">
          <router-link :to="'/receivelog'"> 朋友领券记录 </router-link>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="all">
        <div class="border">
          <img src="../../public/image/no.png" class="icon" />
        </div>
        <div class="no">暂无优惠券</div>
      </div>
    </div>
    <van-overlay :show="overlay" @click="overlay = false">
      <div class="wrapper">
        <div class="ts">点击右上角按钮，分享出去</div>
        <img src="../../public/image/jt.png" alt="">
      </div>
    </van-overlay>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: [],
      page: 1,
      overlay: false,
      url: "https://deer.funengyun.cn/",
      // url: "https://h5.luyopay.com/",
      user: "",
      shop:''
    };
  },
  methods: {
    getData() {
      this.axios({
        method: "post",
        url: "/officialAccount/user/userCouponsList",
        data: {
          page: this.page,
        },
      }).then((res) => {
        console.log(res);
        this.data = res.data.data;
      });
    },
    give(item) {
      this.overlay = true;
      let url = `${this.url}?grant_user_id=${
        this.user.real_user_id
      }&shop_id=${localStorage.getItem("shop")}&id=${item.id}&type=1#/get`;
      console.log(url);
      let that=this
      wx.ready(function () {
        //需在用户可能点击分享按钮前就先调用
        wx.onMenuShareTimeline({
          title: item.name, // 分享标题
          link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: that.shop.logo, // 分享图标
          success: function (res) {
            // 设置成功
            console.log(res);
          },
        });
        wx.onMenuShareAppMessage({
          title: item.name, // 分享标题
          link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: that.shop.logo, // 分享图标
          desc: `${that.user.user_name}送给你一条优惠券，点击领取`, // 分享描述
          success: function () {
            // 设置成功
          },
        });
      });
    },
    getsdk() {
      this.axios({
        url: "/officialAccount/order/jsSdk",
        method: "post",
        data: {
          url: window.location.href,
        },
      }).then((res) => {
        if (res.data.code == 0) {
          var config = res.data.data.config;
          console.log(config);
          wx.config(config);
        }
      });
    },
  },
  mounted() {
    this.getData();
    this.user = this.$store.state.user;
    this.shop=this.$store.state.shop
    console.log(this.shop)
    this.getsdk();
  },
};
</script>
<style scoped>
.give > img {
  width: 100%;
  height: 107px;
}
.list {
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  padding: 0 12px;
  padding-top: 10px;
  margin-top: -20px;
  height: 300px;
  position: absolute;
  background-color: #f8f9f9;
}
.item {
  position: relative;
  margin-top: 7px;
}

.item img {
  width: 100%;
  height: 100px;
}

.price {
  width: 30px;
  font-size: 18px;
  font-weight: 600;
  color: #fe4c0f;
  position: absolute;
  top: 42px;
  left: 20px;
}
.price1 {
  width: 30px;
  height: 33px;
  font-size: 18px;
  font-weight: 600;
  color: #fe4c0f;
  position: absolute;
  top: 12px;
  left: 30px;
}

.line {
  width: 1px;
  height: 86px;
  border-left: 1px dashed #ffb26a;
  position: absolute;
  top: 6px;
  left: 77px;
}

.title {
  width: 174px;
  height: 24px;
  font-size: 17px;

  font-weight: 600;
  color: #323233;
  line-height: 24px;
  position: absolute;
  top: 20px;
  left: 90px;
}

.details {
  width: 196px;
  height: 17px;
  font-size: 12px;
  font-weight: 400;
  color: #686868;
  line-height: 17px;
  position: absolute;
  top: 55px;
  left: 90px;
}

.btn {
  width: 60px;
  height: 30px;
  background: linear-gradient(90deg, #ff8301 0%, #fe4d10 100%);
  border-radius: 30px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  font-size: 13px;
  position: absolute;
  top: 33px;
  right: 10px;
}
.footer {
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  color: #666666;
  margin-top: 50px;
}
.all {
  width: 100%;
  height: 100vh;
  padding-top: 40%;
}

.border {
  text-align: center;
}

.icon {
  width: 160px;
  height: 160px;
  margin: 0 auto;
}

.no {
  font-size: 14px;
  font-weight: 400;
  color: #969799;
  text-align: center;
}
.wrapper {
  display: flex;
  justify-content: center;
  height: 100%;
  position: relative;
}
.ts {
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  margin-top: 140px;
}
.wrapper img{
  width: 35px;height: 128px;
  position: absolute;
  right: 20px;
  top: 10px;
}
</style>