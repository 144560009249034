<template>
  <div style="background-color: #f8f9f9; padding-bottom: 82px">
    <div>
      <div class="header">
        <div class="title">金额储值</div>
        <div class="details" v-if="details.length != 0">
          <div
            :class="active == i ? 'show-item' : 'item'"
            v-for="(p, i) of details"
            :key="i"
            @click="show(i)"
          >
            <div class="one">{{ p.price }}元</div>
            <div class="two" v-if="p.give_amount != 0">
              送{{ p.give_amount }}元
            </div>
          </div>
        </div>
        <van-loading
          size="24px"
          v-else
          style="height: 100px; line-height: 100px; text-align: center"
          >加载中...</van-loading
        >
        <div class="foot">
          <span
           v-if="money!=''"  >提示：再储值{{ money.toFixed(2) }}元可成为{{
              level
            }}等级股东，可获得更多代金券和分红奖励</span
          >
          <span v-else>您已是本店最高等级股东</span>
          <a href="">查看更多详情</a>
        </div>
      </div>
      <van-popup v-model="showPay" style="border-radius: 10px">
        <div class="pay">
          <div class="title1">
            <span>选择支付方式</span>
            <img
              src="../../public/image/close-icon@2x.png"
              alt=""
              style="width: 14px; height: 14px; margin-left: 75px"
              @click="showPay = false"
            />
          </div>
          <div class="wx" @click="pay(1)">
            <div class="left">
              <img
                src="../../public/image/wx-icon@2x.png"
                alt=""
                style="width: 26px; height: 26px"
              />
              <span>微信支付</span>
            </div>
          </div>
          <div class="cash" @click="pay(0)">
            <div class="left">
              <img
                src="../../public/image/cashicon@2x.png"
                alt=""
                style="width: 26px; height: 26px"
              />
              <span>现金支付</span>
            </div>
          </div>
          <div class="cash" @click="pay(3)">
            <div class="left">
              <img
                src="../../public/image/POSicon@2x.png"
                alt=""
                style="width: 26px; height: 26px"
              />
              <span>POS支付</span>
            </div>
          </div>
        </div>
      </van-popup>
      <van-overlay :show="overlay">
        <div class="wrapper">
          <div class="block">
            <div class="top">
              <img src="../../public/image/yes.png" alt="" />
              <div>支付成功</div>
            </div>
            <div
              class="foot"
              @click="
                overlay = false;
                goMe();
              "
            >
              确定
            </div>
          </div>
        </div>
      </van-overlay>
      <van-overlay :show="overlay1">
        <div class="wrapper">
          <div class="block1">
            <div class="top">
              <img :src="src" alt="" />
              <div>该操作需店长扫码确认后继续</div>
            </div>
            <div class="foot" @click="overlay1 = false">取消</div>
          </div>
        </div>
      </van-overlay>
      <div class="footer">
        <div class="btn" @click="showpay()">立即支付</div>
      </div>
    </div>
  </div>
</template>
<script>
import { Dialog } from "vant";
import { Toast } from "vant";
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      overlay: false,
      overlay1: false,
      details: [],
      active: 4,
      show1: require("../../public/image/yes-icon.png"),
      hide: require("../../public/image/no-icon.png"),
      state: true,
      src: "",
      showPay: false,
      money: "",
      level: "",
    };
  },
  methods: {
    goMe() {
      if (window.location.href.indexOf("order_id=") != -1) {
        window.location.href =
          window.location.href.split("order_id")[0] + "#/me";
      } else {
        this.$router.push("/me");
      }
    },
    show(i) {
      this.active = i;
    },
    active2() {
      this.state = false;
    },
    active1() {
      this.state = true;
    },
    showpay() {
      console.log(this.active);
      if (this.active >= this.details.length || this.active < 0) {
        Toast.fail("请选择套餐");
      } else {
        this.showPay = true;
      }
    },
    pay(state) {
      this.showPay = false;
      Toast.loading("正在支付");
      if(sessionStorage.getItem("user")){
        this.axios({
        method: "post",
        url: "/officialAccount/order/insert",
        data: {
          package_id: this.details[this.active].id,
          type: state,
          corp_user_id: sessionStorage.getItem("user"),
          notify_url: window.location.href,
        },
      }).then((res) => {
        if (state == 0||state==3) {
          this.src = res.data.data.json;
          Toast.clear();
          this.overlay1 = true;
          this.checkOrderStatus(res.data.data.order_no, 50);
        } else {
          console.log(res.data, 1);
          Toast.clear();
          window.WeixinJSBridge.invoke(
            "getBrandWCPayRequest",
            res.data.data.json,
            function (res) {
              if (res.err_msg == "get_brand_wcpay_request:ok") {
                // 使用以上方式判断前端返回,微信团队郑重提示：
                //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              }
            }
          );
        }
      });
      }else{
        this.axios({
        method: "post",
        url: "/officialAccount/order/insert",
        data: {
          package_id: this.details[this.active].id,
          type: state,
          notify_url: window.location.href,
        },
      }).then((res) => {
        if (state == 0||state==3) {
          this.src = res.data.data.json;
          Toast.clear();
          this.overlay1 = true;
          this.checkOrderStatus(res.data.data.order_no, 50);
        } else {
          console.log(res.data, 1);
          Toast.clear();
          window.WeixinJSBridge.invoke(
            "getBrandWCPayRequest",
            res.data.data.json,
            function (res) {
              if (res.err_msg == "get_brand_wcpay_request:ok") {
                // 使用以上方式判断前端返回,微信团队郑重提示：
                //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              }
            }
          );
        }
      });
      }
      
    },
    checkOrderStatus(order_no, times) {
      let that = this;
      console.log(times);
      if (this.overlay1) {
        this.axios({
          method: "post",
          url: "/officialAccount/order/verification",
          data: {
            order_no: order_no,
          },
        }).then((res) => {
          console.log(res);
          if (res.data.code !== 0) {
            if (times > 0) {
              times--;
              setTimeout(() => {
                that.checkOrderStatus(order_no, times);
              }, 3000);
            } else {
              Toast.fail("支付失败");
            }
          } else {
            that.overlay1 = false;
            that.overlay = true;
          }
        });
      }
    },
    checkOrderStatus1(order_no, times) {
      let that = this;
      this.axios({
        method: "post",
        url: "/officialAccount/order/verification",
        data: {
          order_no: order_no,
        },
      }).then((res) => {
        console.log(res);
        if (res.data.code !== 0) {
          if (times > 0) {
            times--;
            setTimeout(() => {
              that.checkOrderStatus(order_no, times);
            }, 3000);
          } else {
            Toast.fail("支付失败");
          }
        } else {
          that.overlay1 = false;
          that.overlay = true;
        }
      });
    },
    getback() {
      let url = window.location.href;
      if (url.indexOf("order_id=") != -1) {
        let order_id = url.split("order_id=")[1].split("#")[0];
        this.checkOrderStatus1(order_id, 20);
      }
    },
    getlevel() {
      this.axios({
        url: "/officialAccount/user/level",
        method: "post",
      }).then((res) => {
        let level = res.data.data;
        this.axios({
          url: "/officialAccount/user/index",
          method: "post",
        }).then((res) => {
          let money = res.data.data.cumulative_amount;
          for (let p of level) {
            if (p.money > money) {
              this.level = p.name;
              this.money = p.money - money;
              return;
            }
          }
          console.log(this.money)
        });
      });
    },
  },
  mounted() {
    this.getback();
    this.axios({
      method: "post",
      url: "/officialAccount/order/recharge",
    }).then((res) => {
      console.log(res);
      this.details = res.data.data;
    });
    this.getlevel();
  },
};
</script>
<style scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 311px;
  height: 208px;
  background-color: #fff;
  border-radius: 10px;
}
.block1 {
  width: 311px;
  height: 286px;
  background-color: #fff;
  border-radius: 10px;
}
.block .top {
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.block1 .top {
  height: 238px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.block1 .top img {
  width: 160px;
  height: 160px;
  margin-bottom: 10px;
}
.block .top img {
  margin-bottom: 10px;
}
.block .top div,
.block1 .top div {
  font-size: 16px;
  font-weight: 400;
  color: #323233;
}
.block .foot,
.block1 .foot {
  height: 48px;
  text-align: center;
  line-height: 48px;
  font-size: 16px;
  font-weight: 400;
  color: #576b95;
  border-top: 1px solid #ebedf0;
}
.header {
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px #f5f5f4;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 10px 0;
  margin-bottom: 20px;
}
.title {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  padding: 0 12px;
  margin-bottom: 10px;
}
.title1 {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  height: 50px;
  line-height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.details {
  display: flex;
  flex-wrap: wrap;
  padding-left: 10px;
}
.item {
  width: 30%;
  height: 109px;
  background: #f7f7f7;
  border-radius: 4px;
  margin-bottom: 12px;
  margin-right: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.show-item {
  min-width: 30%;
  height: 109px;
  background: #fff8f0;
  border-radius: 4px;
  margin-bottom: 12px;
  margin-right: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ffb26a;
}
.one {
  font-size: 20px;
  font-weight: 500;
  color: #333333;
}
.two {
  font-size: 12px;
  font-weight: 400;
  color: #999999;
}
.three {
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  font-size: 11px;
  font-weight: 400;
  color: #ffffff;
  background-color: #ff8300;
  text-align: center;
  border-radius: 4px;
}
.foot {
  padding-left: 12px;
}
.foot span {
  font-size: 13px;
  font-weight: 500;
  color: #999999;
}
.foot a {
  font-size: 13px;
  font-weight: 500;
  color: #fe4c0f;
  margin-left: 5px;
  text-decoration: underline;
}
.pay {
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px #f5f5f4;
  border-radius: 10px;
  padding: 0 12px;
  width: 311px;
  border-radius: 10px;
}
.wx,
.cash {
  padding: 0 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 65px;
}
.left {
  display: flex;
  align-items: center;
}
.left span {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  margin-left: 10px;
}
.right {
  width: 22px;
  height: 22px;
}
.footer {
  box-sizing: border-box;
  padding: 8px 12px;
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  width: 100vw;
}
.btn {
  box-sizing: border-box;
  height: 40px;
  text-align: center;
  background: linear-gradient(90deg, #ff8300 0%, #fe4c10 100%);
  border-radius: 25px;
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  line-height: 40px;
}
</style>