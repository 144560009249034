<template>
  <div class="log">
    <div class="title">朋友领取记录</div>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div class="item" v-for="(p, i) of data" :key="i">
        <div class="content">
          {{ p.actual_name }}领取了{{ p.coupon_name }}的优惠券
        </div>
        <div class="state">{{p.is_use==1?'已使用':'已领取'}}</div>
      </div>
    </van-list>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: [],
      loading: false,
      finished: false,
      page: 1,
      endpage: "",
    };
  },
  methods: {
    getlog() {
      this.axios({
        url: "/officialAccount/user/receiveLog",
        method: "post",
        data: {
          page: 1,
        },
      }).then((res) => {
        this.data = res.data.data.list;
        this.page=this.page+1;
        this.endpage = res.data.data.is_end;
        console.log(this.endpage)
      });
    },
    onLoad() {
      if (this.endpage == 1) {
        this.finished = true;
        this.loading = false;
      } else {
        this.loading = true;
        this.axios({
          url: "/officialAccount/user/receiveLog",
          method: "post",
          data: {
            page: this.page,
          },
        }).then((res) => {
          this.loading = false;
          this.data = this.data.concat(res.data.data.list);
          this.page++;
          this.endpage = res.data.data.is_end;
        });
      }
    },
  },
  mounted() {
    this.getlog();
  },
};
</script>
<style scoped>
.log {
  min-height: 100vh;
  background-color: #fff;
  box-shadow: 0px 2px 6px 0px #f5f5f4;
  border-radius: 10px 10px 0px 0px;
  padding: 20px 10px;
}
.title {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  margin-bottom: 10px;
}
.item {
  background: #fff8f0;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.content {
  width: 70%;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}
.state {
  font-size: 13px;
  font-weight: 400;
  color: #fe4c0f;
}
</style>