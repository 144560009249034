import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    show:true,
    n:1 ,
    token:'',
    user:'',
    shop:'',
    title:'',
    discounts:''
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
