<template>
  <div style="background-color: #f8f9f9; padding-bottom: 82px">
    <van-pull-refresh
      v-model="isLoading"
      success-text="刷新成功"
      @refresh="onRefresh"
      style="min-height: 100vh"
    >
      <div class="bg">
        <div class="content">
          <div class="content-left">
            <img :src="data.user_avatar_url" alt="" />
            <div class="name">
              <div>{{ data.user_name }}</div>
              <div>UID:{{ data.user_id }}</div>
            </div>
          </div>
          <!-- <router-link :to="'/integral'" class="content-right">
          <div class="top">
            <img src="../../public/image/yjf.png" alt="" />
            <div>8211</div>
          </div>
          <div class="foot">
            <span>昨日上涨：</span>
            <span>231</span>
            <img src="../../public/image/top-icon.png" alt="" />
          </div>
        </router-link> -->
        </div>
      </div>
      <div class="stored-value">
        <div class="left">
          <div>{{ data.wallet_amount }}</div>
          <div>储值余额</div>
        </div>
        <router-link :to="'/topup'" class="right">
          <img
            src="../../public/image/cz-icon.png"
            alt=""
            style="width: 32px; height: 32px"
          />
          <div>立即储值</div>
        </router-link>
      </div>
      <div class="shareholder">
        <div class="title">股东中心</div>
        <div class="shareholder-content">
          <div class="item" style="padding-right: 10px" @click="share()">
            <div class="item-left">
              <div>股东赚钱</div>
              <div>股东分享有钱赚</div>
            </div>
            <img
              src="../../public/image/invite-icon .png"
              alt=""
              style="width: 49px; height: 49px"
            />
          </div>
          <router-link
            :to="'record'"
            class="item"
            style="padding-left: 10px; border-left: 1px solid #eeeeee"
          >
            <div class="item-left">
              <div>查看分红</div>
              <div>每日收益抢先知</div>
            </div>
            <img
              src="../../public/image/look-icon .png"
              alt=""
              style="width: 49px; height: 49px"
            />
          </router-link>
        </div>
      </div>
      <div class="coupon">
        <div class="title">代金券</div>
        <div class="coupon-item">
          <div class="coupon-content">
            <div class="coupon-left">
              <img
                src="../../public/image/coupon-icon.png"
                alt=""
                style="width: 26px; height: 26px"
              />
              <div>剩余{{ data.coupons }}张</div>
            </div>
            <router-link :to="{path:'/mecoupon',query:{type:0}}" class="btn">使用</router-link>
            <div class="fgx"></div>
          </div>
        </div>
      </div>
    </van-pull-refresh>
    <tabbar></tabbar>
    <div>
      <van-overlay :show="show" class="van-overlay">
        <div class="wrapper">
          <img :src="url" alt="" />
          <div class="wrapper-footer">
            <span>长按可保存海报到相册</span>
            <span @click.stop="refresh()">刷新海报</span>
          </div>
          <img
            src="../../public/image/close.png"
            @click="show = false"
            style="width: 32px; height: 32px; margin-top: 11px"
          />
        </div>
      </van-overlay>
    </div>
  </div>
</template>
<script>
import Tabbar from "../components/Tabbar";
import { Toast } from "vant";
export default {
  components: {
    tabbar: Tabbar,
  },
  data() {
    return {
      data: this.$store.state.user,
      show: false,
      url: "",
      isLoading: false,
    };
  },
  methods: {
    share() {
      if(this.data.is_member==1){
        // wx.ready(() => {
        // this.axios({
        //   method: "post",
        //   url: "/officialAccount/user/poster",
        //   data: {
        //     refresh: 0,
        //     user_id: .getItem("user"),
        //   },
        // }).then((res) => {
        //   console.log(res);
        //   if (res.data.code == 0) {
        //     this.url = res.data.data[0];
        //     this.show = true;

          // } else {
          //   this.getLogin();
          // }
      //   });
      // });
        this.$router.push('/shareholder')
      }else{
        Toast.fail('您还不是本店股东会员')
      }    
    },
    refresh() {
      this.axios({
        url: "/officialAccount/user/poster",
        method: "post",
        data: {
          refresh: 1,
          user_id: sessionStorage.getItem("user"),
        },
      }).then((res) => {
        this.url = res.data.data;
      });
    },
    onRefresh() {
      this.axios({
        method: "post",
        url: "/officialAccount/user/index",
      }).then((res) => {
        this.data = res.data.data;
        this.$store.state.user = res.data.data;
        this.isLoading = false;
      });
    },
  },
  mounted() {
    this.axios({
      method: "post",
      url: "/officialAccount/user/index",
    }).then((res) => {
      this.data = res.data.data;
      this.$store.state.user = res.data.data;
    });
  },
};
</script>
<style scoped>
.bg {
  height: 130px;
  background: linear-gradient(90deg, #ffe1bb 0%, #f1ca90 100%);
  background-size: 100%;
  background-repeat: no-repeat;
}
.content {
  display: flex;
  padding-left: 27px;
  box-sizing: border-box;
  padding-top: 22px;
  align-items: center;
  justify-content: space-between;
}
.content-left {
  display: flex;
  align-items: center;
}
.content-left img {
  width: 66px;
  height: 66px;
  border-radius: 50%;
}
.name {
  margin-left: 10px;
}
.name > div:nth-child(1) {
  font-size: 17px;
  font-weight: 500;
  color: #724404;
}
.name > div:nth-child(2) {
  font-size: 14px;
  font-weight: 400;
  color: #724404;
}
.content-right {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px 0px 0px 10px;
  padding: 3px 15px;
}
.top,
.foot {
  display: flex;
  align-items: center;
}
.top {
  margin-bottom: 3px;
}
.top div {
  font-size: 20px;
  font-weight: 500;
  color: #333333;
  margin-left: 5px;
}
.foot span {
  font-size: 12px;
  font-weight: 400;
  color: #333333;
}
.foot img {
  margin-left: 5px;
}
.stored-value {
  width: 94%;
  margin: 0 auto;
  margin-top: -25px;
  height: 100px;
  padding: 0 10px;
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.left,
.right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.left > div:nth-child(1) {
  font-size: 24px;
  font-weight: 500;
  color: #333333;
  margin-bottom: 5px;
}
.left > div:nth-child(2),
.right > div:nth-child(2) {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
}
.shareholder {
  width: 94%;
  margin: 0 auto;
  margin-top: 10px;
  padding: 15px 10px;
  background-color: #ffffff;
  border-radius: 10px;
}
.title {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  margin-bottom: 15px;
}
.shareholder-content {
  display: flex;
  justify-content: space-between;
}
.item {
  display: flex;
  align-items: flex-end;
  width: 50%;
  justify-content: space-between;
}
.item-left div:nth-child(1) {
  font-size: 14px;
  font-weight: 500;
  color: #333333;
}
.item-left div:nth-child(2) {
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  margin-top: 3px;
}
.coupon {
  width: 94%;
  margin: 0 auto;
  margin-top: 10px;
  padding: 15px 0 15px 10px;
  background-color: #ffffff;
  border-radius: 10px;
}
.coupon-item {
  display: flex;
  flex-wrap: wrap;
}
.coupon-content {
  background-image: url("../../public/image/coupon-bg.png");
  background-size: 100%;
  padding: 12px 20px;
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.coupon-left {
  display: flex;
  align-items: center;
}
.coupon-left div {
  margin-left: 12px;
  font-size: 15px;
  font-weight: 500;
  color: #333333;
}
.btn {
  background: #fee0c4;
  border-radius: 30px;
  font-size: 13px;
  font-weight: 500;
  color: #fe4c0f;
  padding: 4px 17px;
}
.fgx {
  width: 1px;
  height: 49px;
  border: 1px dashed #ffc996;
  position: absolute;
  right: 91px;
}
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 75%;
  margin: 0 auto;
}
.wrapper > img {
  width: 100%;
  height: 69%;
}
.wrapper-footer {
  margin-top: 11px;
  font-size: 13px;
  font-weight: 400;
  color: #ffffff;
}
.wrapper-footer span:nth-child(2) {
  font-size: 15px;
  font-weight: 400;
  color: #fe4c0f;
  margin-left: 10px;
}
</style>