<template>
  <div class="mecoupon">
    <div class="list" v-if="data.length != 0">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div class="item" v-for="(item, index) in data" :key="index">
          <img src="../../public/image/coupon.png" />
          <div class="price" v-if="item.type == 1">
            ¥{{ item.discount_money }}
          </div>
          <div class="price1" v-if="item.type == 2">抵用券</div>
          <div class="line"></div>
          <div class="title" v-if="item.type == 1">
            满{{ item.total_money }}减{{ item.discount_money }}元
          </div>
          <div class="title" v-if="item.type == 2">{{ item.name }}</div>
          <div class="details"  v-if="item.type == 1">{{ item.description }}</div>
          <!-- <div class="btn-head" @click=" overlay=true">膨胀</div> -->
          <router-link
            v-if="item.type == 1"
            :to="{
              path: '/pay',
              query: {
                coupons_id: item.id,
                discount_money: item.discount_money,
                total_money: item.total_money,
              },
            }"
            replace
            class="btn-foot"
            >使用</router-link
          >
          <div v-if="item.type == 2" class="btn-foot" @click="use1(item)">使用</div>
        </div>
      </van-list>
    </div>
    <div v-else>
      <div class="all">
        <div class="border">
          <img src="../../public/image/no.png" class="icon" />
        </div>
        <div class="no">暂无优惠券</div>
      </div>
    </div>
    <van-overlay :show="overlay">
      <div class="wrapper">
        <div class="block">
          <div class="top1">
            <div>将此券分享给好友，好友领取后你的这张优惠券面额会提高</div>
            <img src="../../public/image/lizi.png" alt="" />
          </div>
          <div class="foot1">
            <div @click="overlay = false">取消</div>
            <div>确定</div>
          </div>
        </div>
      </div>
    </van-overlay>
    <van-overlay :show="show2">
      <div class="content1">
        <div style="position: relative">
          <img src="../../public/image/head.png" class="top" />
          <div class="title1">抵用券</div>
          <div class="details1">{{ item.name }}</div>
          <!-- <div class="time">有效期：{{description.start_time}}-{{description.overtime}}</div> -->
        </div>
        <div style="position: relative; margin-top: -200px">
          <img src="../../public/image/foot.png" class="foot" />
          <input
            type="text"
            placeholder="在此输入核销员密码"
            class="img"
            v-model="pwd"
          />
          <input
            type="text"
            placeholder="在此输入桌号后点击核销使用"
            class="img1"
            v-model="num"
          />
          <div class="js" @click="use()">核销使用</div>
          <img
            src="../../public/image/close.png"
            class="close"
            @click="hide()"
          />
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { Dialog,Toast } from "vant";
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      overlay: false,
      data: [],
      page: 1,
      loading: false,
      finished: false,
      show2:false,
      item:'',
      pwd:'',
      num:''
    };
  },
  methods: {
    getData() {
      this.axios({
        method: "post",
        url: "/officialAccount/user/userCouponsList",
        data: {
          page: this.page,
          type: this.type,
        },
      }).then((res) => {
        console.log(res);
        this.data = res.data.data;
        this.data1 = res.data.data;
        this.page++;
      });
    },
    onLoad() {
      if (this.data1.length < 10) {
        this.loading = false;
        this.finished = true;
      } else {
        this.loading = true;
        this.axios({
          url: "/officialAccount/user/userCouponsList",
          method: "post",
          data: {
            page: this.page,
            type: this.type,
          },
        }).then((res) => {
          console.log(res);
          this.data = this.data.concat(res.data.data);
          this.data1 = res.data.data;
          this.page++;
          this.loading = false;
        });
      }
    },
    use1(item){
      this.show2=true
      this.item=item
    },
    hide(){
      this.show2=false
    },
    use() {
      if (!this.pwd) {
        Toast.fail("请输入核销员密码");
      } else if (!this.num) {
        Toast.fail("请输入桌号");
      } else {
        this.axios({
          url: "/officialAccount/order/verifyCoupons",
          method: "post",
          data: {
            id: this.item.id,
            code: this.pwd,
            table_number: this.num,
          },
        }).then((res) => {
          this.show2=false
          if (res.data.code == 0) {
            Toast.success("使用抵用券成功");
            this.getData()
          }else{
            Toast.success(res.data.message);
          }
        });
      }
    },
  },
  mounted() {
    this.type = this.$route.query.type;
    this.getData();
  },
};
</script>
<style scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 311px;
  height: 278px;
  background-color: #fff;
  border-radius: 10px;
}
.block .top1 {
  height: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.block .top1 div {
  padding: 0 24px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #323233;
  margin-bottom: 5px;
}
.block .foot {
  height: 48px;
  border-top: 1px solid #ebedf0;
  display: flex;
}
.block .foot1 div {
  width: 50%;
  height: 100%;
  text-align: center;
  line-height: 48px;
}
.block .foot1 div:first-child {
  border-right: 1px solid #ebedf0;
  font-size: 16px;
  font-weight: 400;
  color: #323233;
}
.block .foot1 div:last-child {
  font-size: 16px;
  font-weight: 400;
  color: #576b95;
}
.item {
  position: relative;
  margin-top: 7px;
}

.item img {
  width: 100%;
  height: 100px;
}

.price {
  height: 33px;
  font-size: 20px;
  font-weight: 600;
  color: #fe4c0f;
  position: absolute;
  top: 40px;
  left: 17px;
}
.price1 {
  width: 30px;
  height: 33px;
  font-size: 18px;
  font-weight: 600;
  color: #fe4c0f;
  position: absolute;
  top: 12px;
  left: 30px;
}

.line {
  width: 1px;
  height: 86px;
  border-left: 1px dashed #ffb26a;
  position: absolute;
  top: 6px;
  left: 75px;
}

.title {
  width: 174px;
  height: 24px;
  font-size: 17px;
  font-weight: 600;
  color: #323233;
  line-height: 24px;
  position: absolute;
  top: 31px;
  left: 90px;
}

.details {
  width: 196px;
  height: 17px;
  font-size: 12px;
  font-weight: 400;
  color: #686868;
  line-height: 17px;
  position: absolute;
  top: 58px;
  left: 90px;
}

.btn-head {
  width: 60px;
  height: 30px;
  background: linear-gradient(90deg, #ff8301 0%, #fe4d10 100%);
  border-radius: 30px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  font-size: 13px;
  position: absolute;
  top: 15px;
  right: 10px;
}
.btn-foot {
  width: 60px;
  height: 30px;
  border: 1px solid #fe4c0f;
  border-radius: 30px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  font-size: 13px;
  position: absolute;
  top: 35px;
  right: 10px;
  color: #fe4c0f;
}
.mecoupon {
  padding: 10px;
  box-sizing: border-box;
}
.all {
  width: 100%;
  height: 100vh;
  padding-top: 40%;
}

.border {
  text-align: center;
}

.icon {
  width: 160px;
  height: 160px;
  margin: 0 auto;
}

.no {
  font-size: 14px;
  font-weight: 400;
  color: #969799;
  text-align: center;
}
.content1 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.content1 .top {
  width: 344px;
  height: 322px;
}

.content1 .foot {
  width: 375px;
  height: 244px;
  z-index: 4;
}

.close {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 240px;
  left: 170px;
}

.title1 {
  width: 25px;
  height: 63px;
  font-size: 15px;
  font-weight: 500;
  color: #fe4c0f;
  line-height: 21px;
  text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.28);
  position: absolute;
  top: 125px;
  left: 80px;
}

.details1 {
  width: 143px;
  font-size: 14px;
  font-weight: 500;
  color: #323233;
  line-height: 20px;
  text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.28);
  position: absolute;
  top: 125px;
  left: 135px;
}

.time {
  width: 161px;
  font-size: 12px;
  font-weight: 400;
  color: #686868;
  line-height: 14px;
  text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.28);
  position: absolute;
  top: 165px;
  left: 135px;
}

.img {
  z-index: 20;
  width: 263px;
  height: 30px;
  position: absolute;
  background-color: #fff;
  top: 85px;
  left: 56px;
  text-align: center;
  font-size: 13px;
  font-weight: 400;
  color: #9d9ea0;
  border-radius: 4px;
}
.img1 {
  z-index: 20;
  width: 263px;
  height: 30px;
  position: absolute;
  background-color: #fff;
  top: 125px;
  left: 56px;
  text-align: center;
  font-size: 13px;
  font-weight: 400;
  color: #9d9ea0;
  border-radius: 4px;
}

.js {
  width: 263px;
  height: 36px;
  background: linear-gradient(180deg, #fff5db 0%, #fee7b4 100%);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.28);
  border-radius: 18px;
  position: absolute;
  top: 170px;
  left: 56px;
  font-size: 13px;
  font-weight: 500;
  color: #dd3217;
  line-height: 36px;
  text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.28);
  text-align: center;
}
</style>