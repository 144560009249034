<template>
  <div class="get">
    <img
      :src="data.user_avatar_url"
      alt=""
      style="width: 60px; heighe: 60px; border-radius: 50px; margin-top: 30px"
    />
    <div class="title">恭喜你</div>
    <div class="title">{{ data.user_name }}送您优惠券啦</div>
    <div class="content">
      <div class="content-coupon">
        <div class="price" v-if="data.coupon_type == 1">
          ¥{{ data.discount_money }}
        </div>
        <div class="price1" v-else>抵用券</div>
        <div class="right">
          <div class="one" v-if="data.coupon_type == 1">
            满{{ data.total_money }}减{{ data.discount_money }}元
          </div>
          <div class="two" style="margin-top: 10px">
            有效期：{{ data.start_time }}至{{ data.overtime }}
          </div>
          <div class="two" style="margin-top: 5px">{{ data.description }}</div>
        </div>
      </div>
    </div>
    <div class="btn">
      <div @click="get()">立即领取</div>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      data: "",
    };
  },
  methods: {
    get() {
      this.axios({
        url: "/officialAccount/user/addCoupons",
        method: "post",
        data: {
          grant_user_id: localStorage.getItem("grant_user_id"),
          id: localStorage.getItem("id"),
          type: localStorage.getItem("type"),
          shop_id: localStorage.getItem("shop"),
        },
      }).then((res) => {
        if (res.data.code == 0) {
          localStorage.removeItem("id");
          localStorage.removeItem("type");
          localStorage.removeItem("grant_user_id");
          Toast.success("领取成功");
          setTimeout(() => {
            this.$router.replace("/");
          }, 1000);
        } else {
          Toast.fail(res.data.message);
        }
      });
    },
  },
  mounted() {
    let url = window.location.href;
    let id = url.split("&id=")[1];
    let type = url.split("type=")[1];
    let grant_user_id = url.split("grant_user_id=")[1];
    let reg = /\d+/g;
    if (id) {
      localStorage.setItem("id", id.match(reg)[0]);
      localStorage.setItem("type", type.match(reg)[0]);
      localStorage.setItem("grant_user_id", grant_user_id.match(reg)[0]);
    }
    this.axios({
      url: "/officialAccount/user/couponInfo",
      method: "post",
      data: {
        grant_user_id: localStorage.getItem("grant_user_id"),
        id: localStorage.getItem("id"),
        type: localStorage.getItem("type"),
      },
    }).then((res) => {
      this.data = res.data.data;
      this.data.start_time = this.data.start_time.split(" 00:")[0];
      this.data.overtime = this.data.overtime.split("00:")[0];
    });
  },
};
</script>
<style scoped>
.get {
  width: 100vw;
  height: 100vh;
  background-image: url("../../public/image/bg@2x.png");
  background-size: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  font-size: 22px;
  font-weight: 600;
  color: #ffffff;
  margin-top: 10px;
}
.content {
  width: 88%;
  height: 153px;
  background-image: url("../../public/image/bg@2x(1).png");
  background-size: 100%;
  margin-top: 20px;
  padding: 26px 16px;
}
.content-coupon {
  width: 292px;
  height: 100px;
  background-image: url("../../public/image/couponbg@2x.png");
  background-size: 100%;
  display: flex;
  align-items: center;
}
.btn {
  width: 100%;
  padding: 0 40px;
  margin-top: 20px;
}
.btn div {
  padding: 11px 0;
  width: 100%;
  background-color: #fdec88;
  color: #ef4547;
  text-align: center;
  box-shadow: 3px 2px 4px 0px rgba(255, 255, 255, 0.5);
  border-radius: 21px;
  font-size: 15px;
}
.price {
  font-size: 24px;
  font-weight: 500;
  color: #fe4c0f;
  margin-left: 20px;
}
.price1 {
  width: 30px;
  height: 33px;
  font-size: 18px;
  font-weight: 600;
  color: #fe4c0f;
  margin-left: 26px;
  margin-top: -35px;
}
.right {
  margin-left: 40px;
}
.one {
  font-size: 17px;
  font-weight: 500;
  color: #323233;
}
.two {
  font-size: 12px;
  font-weight: 400;
  color: #686868;
}
</style>