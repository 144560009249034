<template>
  <div>
    <div class="header">
      <div class="one">
        <img src="../../public/image/1.jpg" alt="" />
        <div>张娜娜</div>
      </div>
      <div class="two">
        <div class="two-head">
          <span>8211</span>
          <span>LB</span>
        </div>
        <div class="two-content">
          <div>
            <span>昨日上涨：</span>
            <span>231</span>
          </div>
          <img src="../../public/image/top-icon.png" alt="" />
        </div>
        <div class="two-foot">6626J积分即将于2023-02-28到期</div>
      </div>
      <router-link :to="'/detail'" class="three">明细</router-link>
    </div>
    <div class="content">
      <div>
        <img
          src="../../public/image/integral-title.png"
          alt=""
          style="margin: 0 auto"
        />
      </div>
      <div class="content-list">
        <div class="item">
          <img src="../../public/image/integral-item1.png" alt="" />
          <div class="item-title">小熊熨烫机小熊熨B-60</div>
          <div class="item-foot">
            <div>2600LB</div>
            <div>兑换</div>
          </div>
        </div>
        <div class="item">
          <img src="../../public/image/integral-item.png" alt="" />
          <div class="item-title">小米手环5新款</div>
          <div class="item-foot">
            <div>4000LB</div>
            <div>兑换</div>
          </div>
        </div>
        <div class="item">
          <img src="../../public/image/integral-item1.png" alt="" />
          <div class="item-title">小熊熨烫机小熊熨B-60</div>
          <div class="item-foot">
            <div>2600LB</div>
            <div>兑换</div>
          </div>
        </div>
        <div class="item">
          <img src="../../public/image/integral-item.png" alt="" />
          <div class="item-title">小米手环5新款</div>
          <div class="item-foot">
            <div>4000LB</div>
            <div>兑换</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.header {
  height: 200px;
  background-image: url("../../public/image/integral-bg.png");
  background-size: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px 0 0 20px;
}
.one img {
  width: 50px;
  height: 50px;
  border-radius: 50px;
}
.one div {
  text-align: center;
  margin-top: 3px;
  font-size: 14px;
  font-weight: 500;
  color: #724404;
}
.two {
  margin-top: 22px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.two-head span:nth-child(1) {
  font-size: 44px;
  font-weight: 500;
  color: #724404;
}
.two-head span:nth-child(2) {
  font-size: 15px;
  font-weight: 500;
  color: #724404;
}
.two-content {
  display: flex;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: #724404;
  align-items: center;
}
.two-content img {
  width: 10px;
  height: 13px;
  margin-left: 3px;
}
.two-foot {
  background: linear-gradient(90deg, #e9b86e 0%, #ebc58b 100%);
  border-radius: 12px;
  padding: 5px 18px;
  margin-top: 10px;
  font-size: 13px;
  font-weight: 400;
  color: #ffffff;
}
.three {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 100px 0px 0px 100px;
  width: 60px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 15px;
  font-weight: 400;
  color: #724404;
}
.content {
  margin-top: -10px;
  padding: 20px 0 5% 12px;
  background: #ffffff;
  box-shadow: 0px 2px 18px 0px #f5f5f4;
  border-radius: 10px 10px 0px 0px;
}
.content-list {
  margin-top: 17px;
  display: flex;
  flex-wrap: wrap;
}
.item {
  width: 46%;
  margin-right: 4%;
  margin-bottom: 12px;
}
.item img{
  width: 100%;
}
.item-title {
  margin-top: 7px;
  font-size: 15px;
  font-weight: 400;
  color: #333333;
}
.item-foot {
  margin-top: 4px;
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.item-foot div:nth-child(1) {
  font-size: 15px;
  font-weight: 500;
  color: #fe4c0f;
}
.item-foot div:nth-child(2) {
  width: 60px;
  height: 26px;
  background: linear-gradient(90deg, #ff8301 0%, #fe4d10 100%);
  border-radius: 30px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  line-height: 26px;
  text-align: center;
}
</style>