<template>
  <div>
    <div style="padding-bottom: 95px" v-if="data">
      <van-pull-refresh
        v-model="isLoading"
        success-text="刷新成功"
        @refresh="onRefresh"
        style="min-height: 100vh"
      >
        <div v-if="data != {}">
          <div class="header">
            <div class="header-con">
              <div class="pos">品牌店</div>
              <div class="header-con-title">
                <div class="header-con-title-left">
                  <img
                    :src="shop.logo"
                    alt=""
                    style="
                      width: 67px;
                      height: 67px;
                      margin-right: 10px;
                      border-radius: 50px;
                    "
                    v-if="shop"
                  />
                  <div class="title-left-con" v-if="data != {}">
                    <div class="title-left-con-one" v-if="data.is_member == 1">
                      尊敬的本店{{ data.level }}股东
                    </div>
                    <div class="title-left-con-one" v-if="data.is_member == 0">
                      尊敬的会员
                    </div>
                    <div class="title-left-con-two">
                      <span>{{ data.user_name }}</span>
                      <span v-if="data.user_sex != 0">{{
                        data.user_sex == 1 ? "先生" : "女士"
                      }}</span>
                    </div>
                    <div class="title-left-con-three">
                      <span>可用余额：</span>
                      <span>{{ data.wallet_amount }}元</span>
                    </div>
                  </div>
                  <div class="title-left-con" v-else>
                    <div class="title-left-con-one">您还不是本店股东会员</div>
                    <router-link :to="'/register'" class="title-left-con-four">
                      立刻办理</router-link
                    >
                  </div>
                </div>
                <img
                  src="../../public/image/ewm-icon.png"
                  alt=""
                  v-if="data != {}"
                  style="width: 44px; height: 44px; display: none"
                  @click="share()"
                />
              </div>
            </div>
          </div>
          <div class="head" v-if="shop">
            <div class="head-title" v-if="shop">
              <router-link :to="'/topup'" class="head-title-content">
                <div class="title-content-left">
                  <img
                    src="../../public/image/index-cz-icon.png"
                    alt=""
                    style="width: 28px; height: 28px"
                  />
                  <div>储值中心</div>
                </div>
                <div class="title-content-right">
                  <div>大额储值，秒变股东</div>
                  <img
                    src="../../public/image/index-more-icon.png"
                    alt=""
                    style="width: 15px; height: 15px"
                  />
                </div>
              </router-link>
            </div>
            <div class="one" v-if="shop">
              <div v-for="(p, i) of shop.imgs" :key="i">
                <img :src="p" @click="preview(i)" />
              </div>
            </div>
            <div class="two" v-if="shop && shop.business_hour != 0">
              <div>营业时间</div>
              <div>{{ shop.business_hour[0] }}-{{ shop.business_hour[1] }}</div>
            </div>
            <div class="two" v-if="shop.address.length != 0">
              <div>门店地址</div>
              <div>{{ shop.address.name }}</div>
            </div>
            <div class="button">
              <div @click="openLocation()" v-if="shop.address.length != 0">
                <img src="../../public/image/qwicon.png" />
                <span>前往门店</span>
              </div>
              <a :href="'tel://' + shop.phone" v-if="shop.address.length != 0">
                <img src="../../public/image/dhicon.png" />
                <span>联系店家</span>
              </a>
            </div>
          </div>
          <van-overlay v-if="show2">
            <div class="content1">
              <div style="position: relative">
                <img src="../../public/image/head.png" class="top" />
                <div class="title1">抵用券</div>
                <div class="details">{{ discounts.name }}</div>
                <!-- <div class="time">有效期：{{description.start_time}}-{{description.overtime}}</div> -->
              </div>
              <div style="position: relative; margin-top: -200px">
                <img src="../../public/image/foot.png" class="foot" />
                <input
                  type="text"
                  placeholder="在此输入核销员密码"
                  class="img"
                  v-model="pwd"
                />
                <input
                  type="text"
                  placeholder="在此输入桌号后点击核销使用"
                  class="img1"
                  v-model="num"
                />
                <div class="js" @click="use()">核销使用</div>
                <img
                  src="../../public/image/close.png"
                  class="close"
                  @click="hide()"
                />
              </div>
            </div>
          </van-overlay>
        </div>

        <div class="footer">
          <div class="footer1">
            <img src="../../public/image/logo @2x.png" alt="" />
            <div>鹿有科技提供技术支持</div>
          </div>
          <!-- <div style="text-align: center; margin-top: 5px" @click="clear()">
          清除缓存
        </div> -->
        </div>
      </van-pull-refresh>
      <div>
        <van-overlay :show="show" class="van-overlay" @click="show = false">
          <div class="wrapper">
            <img :src="url" alt="" />
            <div class="wrapper-footer">
              <span>长按可保存海报到相册</span>
              <span @click.stop="refresh()">刷新海报</span>
            </div>
            <img
              src="../../public/image/close.png"
              @click="hide()"
              style="width: 32px; height: 32px; margin-top: 11px"
            />
          </div>
        </van-overlay>
      </div>

      <tabbar></tabbar>
    </div>
    <van-loading size="24px" vertical style="padding-top: 250px" v-else
      >加载中...</van-loading
    >
  </div>
</template>
<script>
import { ImagePreview, Toast } from "vant";
import Tabbar from "../components/Tabbar";
import { Dialog } from "vant";
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
    tabbar: Tabbar,
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  data() {
    return {
      h: 0,
      shop: this.$store.state.shop,
      show1: this.$store.state.show,
      show2: this.$store.state.discounts != "" ? true : false,
      data: this.$store.state.user,
      show: false,
      url: "",
      isLoading: false,
      discounts: this.$store.state.discounts,
      pwd: "",
      num: "",
    };
  },
  methods: {
    use() {
      if (!this.pwd) {
        Toast.fail("请输入核销员密码");
      } else if (!this.num) {
        Toast.fail("请输入桌号");
      } else {
        this.axios({
          url: "/officialAccount/order/verifyCoupons",
          method: "post",
          data: {
            id: this.discounts.id,
            code: this.pwd,
            table_number: this.num,
          },
        }).then((res) => {
          if (res.data.message == "成功") {
            this.discounts = "";
            this.$store.state.discounts = "";
            Toast.success("使用抵用券成功");
          }
        });
      }
    },
    clear() {
      localStorage.clear();
    },
    onRefresh() {
      this.getsdk();
      this.getdata();
      this.getshop();
      this.isLoading = false;
    },
    preview(i) {
      ImagePreview({
        images: this.shop.imgs,
        startPosition: i,
      });
    },
    hide() {
      this.$store.state.show = false;
      this.show = false;
    },
    show(i) {
      this.prop = i;
    },
    getsdk() {
      this.axios({
        url: "/officialAccount/order/jsSdk",
        method: "post",
        data: {
          url: window.location.href,
        },
      }).then((res) => {
        if (res.data.code == 0) {
          var config = res.data.data.config;
          console.log(config);
          wx.config(config);
        } else {
          this.getLogin();
        }
      });
    },
    openLocation() {
      wx.ready(() => {
        let lat = parseFloat(this.shop.address.lat);
        let lng = parseFloat(this.shop.address.lng);
        wx.openLocation({
          latitude: this.shop.address.lat ? lat : 37.803001, // 纬度，浮点数，范围为90 ~ -90
          longitude: this.shop.address.lng ? lng : 112.56239, // 经度，浮点数，范围为180 ~ -180。
          name: this.shop.address.name
            ? this.shop.address.name
            : "山西省太原市小店区智创城", // 位置名
          address: this.shop.address.name
            ? this.shop.address.name
            : "山西省太原市小店区智创城",
          scale: 16,
        });
      });
    },
    share() {
      wx.ready(() => {
        this.axios({
          method: "post",
          url: "/officialAccount/user/poster",
          data: {
            refresh: 0,
            user_id: sessionStorage.getItem("user"),
            shop_id: localStorage.getItem("shop")
          },
        }).then((res) => {
          console.log(res);
          if (res.data.code == 0) {
            this.url = res.data.data[0];
            this.show = true;
          } else {
            this.getLogin();
          }
        });
      });
    },
    // onMenuShare() {
      // this.axios({
      //   method: "post",
      //   url: "/officialAccount/index/share",
      // }).then((res) => {
      // wx.ready(() => {
        // wx.updateTimelineShareData({
        //   title: '你好',
        //   link: window.location.href,
        //   imgUrl: '',
        // });
        // wx.onMenuShareAppMessage({
          // title: "你好",
          // link: window.location.href,
          // imgUrl: "",
          // desc: "",
        // });
        // wx.onMenuShareQQ(res.data.data.share);
        // wx.onMenuShareWeibo(res.data.data.share);
        // wx.onMenuShareQZone(res.data.data.share);
      // });
      // });
    // },
    refresh() {
      this.axios({
        url: "/officialAccount/user/poster",
        method: "post",
        data: {
          refresh: 1,
          user_id: sessionStorage.getItem("user"),
        },
      }).then((res) => {
        console.log(res);
        this.url = res.data.data;
      });
    },
    getdata() {
      this.axios({
        method: "post",
        url: "/officialAccount/user/index",
      }).then((res) => {
        this.data = res.data.data;
        this.$store.state.user = res.data.data;
      });
    },
    getshop() {
      this.axios({
        methods: "post",
        url: "/officialAccount/index/index",
      }).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          this.shop = res.data.data.shop;
          this.$store.state.shop = res.data.data.shop;
          document.title = this.shop.name;
          this.$store.state.title = this.shop.name;
        } else {
          this.getLogin();
        }
      });
    },
  },
  mounted() {
    console.log(this.show2);
    this.getdata();
    this.getshop();
    this.getsdk();
    // this.onMenuShare();
    document.title = this.$store.state.title;
    // let url = window.location.href;
    // let shop = url.split("shop_id=")[1];
    // let id = url.split("&id=")[1];
    // let type=url.split("type=")[1];
    // let grant_user_id=url.split("grant_user_id=")[1]
    // let reg = /\d+/g;
    // if (id) {
    //   localStorage.setItem("shop", shop.match(reg)[0]);
    //   this.axios({
    //     url: "/officialAccount/user/addCoupons",
    //     method: "post",
    //     data: {
    //       id: id.match(reg)[0],
    //       shop_id: shop.match(reg)[0],
    //       type:type.match(reg)[0],
    //       grant_user_id:grant_user_id.match(reg)[0]
    //     },
    //   }).then((res) => {
    //     if (res.data.code == 0) {
    //       Toast.success("领取成功");
    //       setTimeout(() => {
    //         window.location.href = window.location.href.split("?")[0] ;
    //       }, 2000);
    //     } else {
    //       Toast.fail("领取失败");
    //       setTimeout(() => {
    //         window.location.href = window.location.href.split("?")[0] ;
    //       }, 2000);
    //     }
    //   });
    // }
  },
};
</script>
<style scoped>
.header {
  padding: 25px 12px 0 12px;
}
.header-con {
  background-image: url("../../public/image/index-bg.png");
  background-size: 100%;
  background-repeat: no-repeat;
  min-height: 130px;
  position: relative;
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
  padding-top: 25px;
  width: 100%;
}
.pos {
  width: 50px;
  height: 20px;
  background: linear-gradient(90deg, #ff8301 0%, #fe4d10 100%);
  border-radius: 8px 0px 8px 0px;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  line-height: 20px;
}
.header-con-title {
  display: flex;
  justify-content: space-between;
  padding: 0 12px;
  align-items: center;
}
.header-con-title-left {
  display: flex;
  align-items: center;
}
.title-left-con {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.title-left-con-one {
  font-size: 14px;
  font-weight: 400;
  color: #724404;
  margin-bottom: 2px;
}
.title-left-con-two {
  margin-bottom: 2px;
}
.title-left-con-two span:nth-child(1) {
  font-size: 17px;
  font-weight: 500;
  color: #724404;
}
.title-left-con-two span:nth-child(2) {
  font-size: 14px;
  font-weight: 400;
  color: #724404;
  margin-left: 5px;
}
.title-left-con-three {
  width: 126px;
  height: 20px;
  background: #ca9a5b;
  border-radius: 3px;
  font-size: 13px;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  text-align: center;
}
.title-left-con-four {
  width: 80px;
  height: 26px;
  background: #ca9a5b;
  border-radius: 3px;
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  line-height: 26px;
  text-align: center;
  margin-top: 5px;
}
.head {
  background-image: url("../../public/image/hd.png");
  background-repeat: no-repeat;
  background-size: 100%;
  height: 356px;
  padding-top: 33px;
  margin-top: -25px;
  position: relative;
}
.head-title {
  padding: 0 12px;
}
.head-title-content {
  height: 60px;
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  background-image: url("../../public/image/index-bg2.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
.title-content-left {
  display: flex;
  align-items: center;
}
.title-content-left div {
  font-size: 14px;
  font-weight: 500;
  color: #724404;
  margin-left: 10px;
}
.title-content-right {
  display: flex;
  align-items: center;
}
.title-content-right div {
  font-size: 13px;
  font-weight: 400;
  color: #724404;
  margin-right: 10px;
}
.one {
  display: flex;
  overflow: auto;
  padding: 12px 0;
  width: 93.4%;
  margin: 0 auto;
}
.one div {
  width: 140px;
  height: 105px;
  margin-right: 12px;
  flex: none;
  display: flex;
  align-items: center;
}
.one img {
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
  border-radius: 6px;
}
.two {
  padding: 0 12px;
  margin-bottom: 8px;
  display: flex;
}
.two div {
  font-size: 15px;
}
.two div:first-child {
  color: #666;
  width: 27%;
}
.two div:last-child {
  width: 70%;
}
.button {
  display: flex;
  justify-content: space-around;
  margin-top: 12px;
}

.button > div,
.button > a {
  width: 44%;
  height: 45px;
  border-radius: 2px;
  border: 1px solid #ebebeb;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button img {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}
.content {
  background: #f8f9f9;
  margin-top: 20px;
  padding: 10px;
}
.tab {
  display: flex;
  justify-content: space-around;
  margin: 10px 0;
}
.tab-item {
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  color: #333;
}
.active-item {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #323233;
  border-bottom: 3px solid #ff7f00;
}
.list {
  padding-bottom: 82px;
}
.list .item {
  position: relative;
  border-radius: 10px;
  background-color: #fff;
}

.item img {
  width: 100%;
  height: 175px;
}

.item .image {
  position: absolute;
  top: 5px;
  left: 10px;
  height: 25px;
  background: #fe8000;
  border-radius: 4px;
  padding: 0 3px;
  line-height: 25px;
  display: flex;
  align-items: center;
  font-size: 10px;
  color: #fff;
}

.item > .image > span:first-child {
  width: 4px;
  height: 4px;
  border: 1px solid #ffffff;
  border-radius: 50px;
  display: inline-block;
  margin-top: 13px;
  position: absolute;
  top: -10px;
  left: 1px;
}

.item > .image > span:nth-child(2) {
  width: 42px;
  font-size: 14px;
  margin-left: 3px;
  font-weight: 600;
  color: #ffffff;
  margin-right: 3px;
  display: inline-block;
  margin-top: 3px;
}
.item > .image > span:nth-child(3),
.item > .image > span:nth-child(4),
.item > .image > span:nth-child(5) {
  font-size: 10px;
  color: #fe5500;
  background-color: #fff;
  margin: 0 1px;
  display: inline-block;
  height: 13px;
  line-height: 13px;
  padding: 0 2px;
  border-radius: 3px;
}

.list .title,
.list .price {
  padding: 5px 8px;
  border-radius: 10px;
}
.list .title > div:first-child {
  width: 322px;
  height: 25px;
  font-size: 18px;
  font-weight: 500;
  color: #323233;
  line-height: 25px;
}

.list .title > div:last-child {
  width: 331px;
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
}
.list .price > span:first-child,
.list .price > span:nth-child(6) {
  font-size: 13px;
  color: #fe4c0f;
  font-weight: 400;
}
.list .price > span:nth-child(2) {
  font-size: 22px;
  color: #fe4c0f;
  font-weight: 600;
}
.list .price > span:nth-child(3) {
  font-size: 11px;
  color: #b6b6b6;
  margin-left: 3px;
  text-decoration: line-through;
}
.list .price > span:nth-child(4) {
  font-size: 15px;
  color: #b6b6b6;
  text-decoration: line-through;
}
.list .price > span:nth-child(5) {
  font-size: 13px;
  font-weight: 400;
  color: #666666;
  line-height: 18px;
  margin-left: 8px;
}
.list .price > span:last-child {
  margin-left: 13px;
  background: #fff2ed;
  border-radius: 5px;
  padding: 2px 4px;
}

.off {
  display: none;
}

.content1 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.content1 .top {
  width: 344px;
  height: 322px;
}

.content1 .foot {
  width: 375px;
  height: 244px;
  z-index: 4;
}

.close {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 240px;
  left: 170px;
}

.title1 {
  width: 25px;
  height: 63px;
  font-size: 15px;
  font-weight: 500;
  color: #fe4c0f;
  line-height: 21px;
  text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.28);
  position: absolute;
  top: 125px;
  left: 80px;
}

.details {
  width: 143px;
  font-size: 14px;
  font-weight: 500;
  color: #323233;
  line-height: 20px;
  text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.28);
  position: absolute;
  top: 125px;
  left: 135px;
}

.time {
  width: 161px;
  font-size: 12px;
  font-weight: 400;
  color: #686868;
  line-height: 14px;
  text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.28);
  position: absolute;
  top: 165px;
  left: 135px;
}

.img {
  z-index: 20;
  width: 263px;
  height: 30px;
  position: absolute;
  background-color: #fff;
  top: 85px;
  left: 56px;
  text-align: center;
  font-size: 13px;
  font-weight: 400;
  color: #9d9ea0;
  border-radius: 4px;
}
.img1 {
  z-index: 20;
  width: 263px;
  height: 30px;
  position: absolute;
  background-color: #fff;
  top: 125px;
  left: 56px;
  text-align: center;
  font-size: 13px;
  font-weight: 400;
  color: #9d9ea0;
  border-radius: 4px;
}

.js {
  width: 263px;
  height: 36px;
  background: linear-gradient(180deg, #fff5db 0%, #fee7b4 100%);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.28);
  border-radius: 18px;
  position: absolute;
  top: 170px;
  left: 56px;
  font-size: 13px;
  font-weight: 500;
  color: #dd3217;
  line-height: 36px;
  text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.28);
  text-align: center;
}
.footer {
  margin-top: 80px;
}
.footer1 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer img,
.footer1 img {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}
.footer div,
.footer1 img {
  font-size: 11px;
  font-weight: 400;
  color: #666666;
}
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 75%;
  margin: 0 auto;
}
.wrapper > img {
  width: 100%;
  height: 69%;
}
.wrapper-footer {
  margin-top: 11px;
  font-size: 13px;
  font-weight: 400;
  color: #ffffff;
}
.wrapper-footer span:nth-child(2) {
  font-size: 15px;
  font-weight: 400;
  color: #fe4c0f;
  margin-left: 10px;
}
</style>