import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import Me from '../views/Me.vue'
import Pay from '../views/Pay'
import MeCoupon from '../views/MeCoupon'
import Register from '../views/Register'
import topUp from '../views/topUp'
import give from '../views/give'
import chat from '../views/chat'
import integral from '../views/integral'
import detail from '../views/detail'
import record from '../views/record'
import shareholder from '../views/shareholder'
import share from '../views/share'
import receivelog from '../views/receivelog'
import success from '../views/success'
import get from '../views/get'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/me',
    name: 'Me',
    meta:{
      title:"个人中心",
    },
    component: Me
  },
  {
    path: '/pay',
    name: 'Pay',
    meta:{
      keepAlive: true,
      isBack: false
    },
    component: Pay
  },
  {
    path: '/mecoupon',
    name: 'MeCoupon',
    meta:{
      title:"选择优惠劵"
    },
    component: MeCoupon
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/topup',
    name: 'topUp',
    meta:{
      title:"储值中心"
    },
    component: topUp
  },
  {
    path: '/give',
    name: 'give',
    meta:{
      title:"赠送优惠劵"
    },
    component: give
  },
  {
    path: '/chat',
    name: 'chat',
    component: chat
  },
  {
    path: '/integral',
    name: 'integral',
    meta:{
      title:"云积分"
    },
    component: integral
  },
  {
    path: '/detail',
    name: 'detail',
    component: detail
  },
  {
    path: '/record',
    name: 'record',
    meta:{
      title:"分红记录"
    },
    component: record
  },
  {
    path: '/shareholder',
    name: 'shareholder',
    meta:{
      title:"股东中心"
    },
    component: shareholder
  },
  {
    path: '/share',
    name: 'share',
    meta:{
      title:"优惠券"
    },
    component: share
  },
  {
    path: '/receivelog',
    name: 'receivelog',
    meta:{
      title:"领取记录"
    },
    component: receivelog
  },
  {
    path: '/success',
    name: 'success',
    meta:{
      title:"支付成功"
    },
    component: success
  },
  {
    path: '/get',
    name: 'get',
    meta:{
      title:"领取优惠券"
    },
    component: get
  },
]

const router = new VueRouter({
  routes
})

export default router
