<template>
  <div>
    <!-- <div class="ns"></div> -->
    <div class="register">
      <div class="bg">
        <img src="../../public/image/bg.png" />
      </div>
      <div class="title">注册会员</div>
      <div class="content">
        <div class="header">昵称</div>
        <div>
          <input
            type="span"
            placeholder="请输入昵称"
            class="input"
            placeholder-class="span"
            v-model="user.name"
          />
        </div>
        <div class="header">手机号</div>
        <div>
          <input
            type="number"
            placeholder="请输入手机号"
            class="input"
            placeholder-class="span"
            v-model="user.phone"
          />
        </div>
        <div class="yzm" @click="send_verify()" v-if="show">
          {{ codevalue }}
        </div>
        <div class="yzm" v-else>{{ time }}s</div>
        <div class="header">验证码</div>
        <div>
          <input
            type="number"
            placeholder="请输入验证码"
            class="input"
            placeholder-class="span"
            v-model="user.code"
          />
        </div>
        <div class="header">性别</div>
        <div class="input" @click="show2()">
          <span
            :style="{ color: user.value == '请选择性别' ? '#bbbbbb' : '#000' }"
            >{{ user.value }}</span
          >
        </div>
        <van-popup v-model="showPicker" round position="bottom">
          <van-picker
            show-toolbar
            :columns="columns"
            @cancel="showPicker = false"
            @confirm="onConfirm"
          />
        </van-popup>
      </div>
      <div class="btn" v-if="show1" @click="getReg()">登录</div>
      <div class="btn" v-else>登录</div>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      columns: ["男", "女"],
      showPicker: false,
      user: {
        value: "请选择性别",
        name: "",
        phone: "",
        code: "",
      },
      show: true,
      codevalue: "发送验证码",
      time: 60,
      show1: true,
    };
  },
  methods: {
    onConfirm(value) {
      console.log(value);
      this.user.value = value;
      this.showPicker = false;
    },
    show2() {
      this.showPicker = true;
    },
    getReg() {
      this.show1 = false;
      let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      this.axios({
        url: "/sms/check_verify",
        method: "post",
        data: {
          mobile: this.user.phone,
          code: this.user.code,
        },
      }).then((res) => {
        console.log(res)
        if (this.user.name == "") {
          Toast.fail("请填写昵称");
        } else if (!reg.test(this.user.phone)) {
          Toast.fail("手机号格式不正确");
        } else if (this.user.value == "请选择性别") {
          Toast.fail("请选择性别");
        } else if (res.data.code != 0) {
          Toast.fail("请填写正确的验证码");
        } else {
          if (sessionStorage.getItem("user")) {
            this.axios({
              url: "/officialAccount/user/registerMember",
              method: "post",
              data: {
                user_name: this.user.name,
                phone: this.user.phone,
                sex: this.user.value == "男" ? 1 : 0,
                code: this.user.code,
                corp_user_id: sessionStorage.getItem("user"),
              },
            }).then((res) => {
              this.show1 = true;
              if (res.data.code == 0) {
                this.$store.state.discounts = res.data.data;
                Toast.success("登录成功");
                let url = window.location.href;
                let id = url.split("&id=")[1];
                if (id) {
                  this.$router.push({ path: "/get" });
                }else{
                  this.$router.push({ path: "/" });
                }
              } else {
                Toast.fail(res.data.message);
              }
            });
          } else {
            this.axios({
              url: "/officialAccount/user/registerMember",
              method: "post",
              data: {
                user_name: this.user.name,
                phone: this.user.phone,
                sex: this.user.value == "男" ? 1 : 0,
                code: this.user.code,
              },
            }).then((res) => {
              this.show1 = true;
              if (res.data.code == 0) {
                this.$store.state.discounts = res.data.data;
                Toast.success("登录成功");
                if (localStorage.getItem("id")) {
                  this.$router.push({ path: "/get" });
                }else{
                  this.$router.push({ path: "/" });
                }
              }else{
                Toast.fail(res.data.message);
              }
            });
          }
        }
      });
    },
    send_verify() {
      let reg = /^[1][3,4,5,7,6,8,9][0-9]{9}$/;
      if (!reg.test(this.user.phone)) {
        Toast.fail("手机号格式不正确");
      } else {
        this.show = false;
        this.axios({
          method: "post",
          url: "/sms/send_verify",
          data: {
            mobile: this.user.phone,
          },
        }).then((res) => {
          if (res.data.msg == "发送成功") {
            this.getCode();
            Toast.success("发送成功");
          }
        });
      }
    },
    getCode() {
      if (this.time == 0) {
        this.show = true;
        this.codevalue = "重新发送";
      } else {
        this.time--;
        setTimeout(() => {
          this.getCode();
        }, 1000);
      }
    },
  },
};
</script>
<style scoped>
.ns {
  width: 100%;
  height: 95px;
  text-align: center;
  line-height: 127px;
  color: white;
  font-weight: bold;
  background: linear-gradient(316deg, #ff8101 0%, #fe4d0f 100%);
}

.register {
  height: 725px;
  background-color: #f8f9f9;
  position: relative;
}

.bg {
  height: 271px;
}

.bg img {
  width: 100%;
  height: 100%;
}

.title {
  position: absolute;
  top: 11px;
  left: 20px;
  height: 33px;
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  line-height: 33px;
}

.content {
  width: 92%;
  height: 440px;
  background: #ffffff;
  box-shadow: 0px 1px 6px 0px #f6f6f6;
  border-radius: 5px;
  position: absolute;
  top: 68px;
  left: 4%;
  padding: 14px 18px 28px 17px;
  box-sizing: border-box;
}

.header {
  width: 294px;
  height: 40px;
  font-size: 17px;
  margin-top: 10px;
  font-weight: 400;
  color: #333333;
  line-height: 40px;
}

.span {
  width: 120px;
  height: 40px;
  font-size: 17px;
  font-weight: 300;
  color: #bbbbbb;
  line-height: 40px;
}

.input {
  width: 100%;
  height: 50px;
  background: #f7f7f7;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 5px 12px;
  border: none;
  line-height: 40px;
}

.btn {
  width: 92%;
  height: 50px;
  background: linear-gradient(270deg, #fe4c10 0%, #ff8400 100%);
  border-radius: 28px;
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
  line-height: 50px;
  text-align: center;
  position: absolute;
  top: 540px;
  left: 4%;
}

.yzm {
  width: 80px;
  height: 28px;
  border-radius: 4px;
  border: 1px solid #fe4c0f;
  font-size: 13px;

  font-weight: 400;
  color: #fe4c0f;
  line-height: 28px;
  text-align: center;
  position: absolute;
  right: 30px;
  top: 175px;
}
</style>