<template>
  <div>
    <div class="top" v-if="levelimg.bg1" :style="{backgroundImage: 'url(' + levelimg.bg1 + ')'}">
      <div class="one" >
        <img :src="user.user_avatar_url" alt="" />
        <div class="top-content" :style="{color:levelimg.titlecolor}">
          <div class="name">{{ user.user_name }}</div>
          <div class="welcome">您好，欢迎光临股东中心</div>
        </div>
      </div>
      <div class="two" v-if="levelimg.bg" :style="{backgroundImage: 'url(' + levelimg.bg + ')'}">
        <div class="two-head">
          <img :src="levelimg.logo" alt="" />
          <div class="two-title">你目前是{{ user.level }}股东</div>
        </div>
        <div class="two-footer" :style="{color:levelimg.color}">
          <span v-if="money!=''">再储值{{ money }}元可成为{{ level }}股东</span>
          <span v-else>您已是本店最高等级股东</span>
          <span :style="{border: '1px solid'+ levelimg.color}">查看等级权益</span>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content-item">
        <div class="title">获取分红</div>
        <div class="discounts" @click="share()">
          <img src="../../public/image/yhqicon @2x.png" alt="" />
          <div class="discounts-content">
            <div class="discounts-title">优惠券</div>
            <div class="discounts-details">分享优惠券得分红</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      user: this.$store.state.user,
      level: "",
      money: 0,
      bg: [
        {
          bg: require("../../public/image/1bg.png"),
          bg1: require("../../public/image/1bg@2x.png"),
          logo: require("../../public/image/1icon@2x.png"),
          color:'#EDF6FF',
          titlecolor:'#333333'
        },
        {
          bg: require("../../public/image/2bg.png"),
          bg1: require("../../public/image/2bg@2x.png"),
          logo: require("../../public/image/2icon@2x.png"),
          color:'#C7D7EF',
          titlecolor:'#333333'
        },
        {
          bg: require("../../public/image/3bg.png"),
          bg1: require("../../public/image/3bg@2x.png"),
          logo: require("../../public/image/3icon@2x.png"),
          color:'#EDD1B5',
          titlecolor:'#724404'
        },
        {
          bg: require("../../public/image/4bg.png"),
          bg1: require("../../public/image/4bg@2x.png"),
          logo: require("../../public/image/4icon@2x.png"),
          color:'#EDD1B5',
          titlecolor:'#663725'
        },
        {
          bg: require("../../public/image/5bg.png"),
          bg1: require("../../public/image/5bg@2x.png"),
          logo: require("../../public/image/5icon@2x.png"),
          color:'#A99574',
          titlecolor:'#FFFFFF'
        },
      ],
      levelimg:{

      }
    };
  },
  methods: {
    getlevel() {
      this.axios({
        url: "/officialAccount/user/level",
        method: "post",
      }).then((res) => {
        let level = res.data.data;
        this.axios({
          url: "/officialAccount/user/index",
          method: "post",
        }).then((res) => {
          this.user=res.data.data;
          let money = this.user.cumulative_amount;
          for(let i=0;i<level.length;i++){
            if(level[i].id===this.user.level_id){
              this.levelimg=this.bg[i]
              console.log(this.levelimg)
            }
          }
          for (let p of level) {
            if (p.money > money) {
              this.level = p.name;
              this.money = p.money - money;
              return;
            }
          }
        });
      });
    },
    share() {
      this.$router.push("./share");
    },
    
  },
  mounted() {
    this.getlevel();
  },
};
</script>
<style scoped>
.top {
  background-image: url("../../public/image/gdbg@2x.png");
  width: 100vw;
  height: 215px;
  background-size: 100% 215px;
  background-repeat: no-repeat;
  padding: 15px 10px;
}
.one {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.one img {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  margin-left: 5px;
}
.top-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 5px;
}
.name {
  font-size: 15px;
  font-weight: 500;
}
.welcome {
  font-size: 13px;
}
.two {
  background-image: url("../../public/image/hybg@2x.png");
  width: 100%;
  height: 120px;
  background-size: 100% 120px;
  background-repeat: no-repeat;
  padding: 12px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 9px;
}
.two-head {
  display: flex;
  align-items: center;
}
.two-head img {
  width: 48px;
  height: 48px;
}
.two-title {
  font-size: 17px;
  font-weight: 500;
  color: #ffffff;
  margin-left: 10px;
}
.two-footer span:nth-child(1) {
  font-size: 13px;
  font-weight: 400;
}
.two-footer span:nth-child(2) {
  font-size: 12px;
  font-weight: 400;
  margin-left: 5px;
  border-radius: 9px;
  padding: 2px 5px;
}
.content {
  width: 100vw;
  border-radius: 10px 10px 0 0;
  background: #f8f9f9;
  margin-top: -10px;
  padding: 20px 10px;
}
.content-item {
  background: #ffffff;
  box-shadow: 0px 2px 12px 0px #f5f5f5;
  border-radius: 6px;
  width: 100%;
  padding: 12px;
}
.title {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
}
.discounts {
  background-image: url("../../public/image/djqbg @2x.png");
  background-size: 100% 58px;
  width: 100%;
  height: 58px;
  background-repeat: no-repeat;
  margin-top: 10px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
}
.discounts {
  display: flex;
  align-items: center;
}
.discounts img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.discounts-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.discounts-title {
  font-size: 14px;
  font-weight: 500;
  color: #333333;
}
.discounts-details {
  font-size: 12px;
  font-weight: 400;
  color: #999999;
}
</style>