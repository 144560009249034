<template>
  <div class="detail">
    <div class="cash">
      <div class="num">
        <span style="font-size: 15px">¥</span>
        <span>{{fh.toFixed(2)}}</span>
      </div>
      <div class="btn" @click="cash()">申请提现</div>
    </div>
    <van-tabs v-model="active" color="#FF7E02" background="#f8f9f9">
      <van-tab title="分红记录">
        <div v-if="fhdata.length!=0">
          <div v-for="(p, i) of fhdata" :key="i">
          <div class="item">
            <div>
              <p>分红</p>
              <p>{{p.add_time}}</p>
            </div>
            <div>{{ p.money }}元</div>
          </div>
        </div>
        </div>
        <div v-else style="margin-top:10px;text-align: center;">暂无分红记录</div>
      </van-tab>

      <van-tab title="提现记录" v-if="fhdata">
        <div v-if="txdata.length!=0">
        <div v-for="(p, i) of txdata" :key="i">
          <div class="item">
            <div>
              <p>提现</p>
              <p>{{p.add_time}}</p>
            </div>
            <div>
              <p>+{{ p.withdraw_amount }}元</p>
              <p>{{p.withdraw_state==0?'提现中':p.withdraw_state==1?'提现成功':'提现失败'}}</p>
            </div>
          </div>
        </div>
        </div>
        <div v-else style="margin-top:10px;text-align: center;">暂无提现记录</div>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import {  Toast } from "vant";
export default {
  data() {
    return {
      fhdata: [],
      txdata: [],
      active: 0,
      fh:0
    };
  },
  methods:{
    getFh(){
      this.axios({
        method:'post',
        url:'/officialAccount/user/commissionLog',
        data:{
          page:1
        }
      }).then(res=>{
        this.fhdata=res.data.data
        console.log(this.fhdata)
      })
    },
    getTx(){
      this.axios({
        method:'post',
        url:'/officialAccount/user/withdrawLog',
        data:{
          page:1
        }
      }).then(res=>{
        this.txdata=res.data.data
        console.log(this.txdata)
      })
    },
    allFh(){
      this.axios({
        url:'/officialAccount/user/commissionTotalMoney',
        method:'post',
      }).then(res=>{
        console.log(res)
        this.fh=res.data.data
      })
    },
    cash(){
      this.axios({
        method:'post',
        url:'/officialAccount/user/withdraw',
      }).then(res=>{
        if(res.data.code===0){
          Toast.success('申请成功')
          this.allFh()
          this.getTx()
        }else{
          Toast.fail(res.data.message)
        }
      })
    }
  },
  mounted() {
    this.getFh();
    this.getTx()
    this.allFh()
  }
};
</script>
<style scoped>
.detail {
  background: #f8f9f9;
  box-shadow: 0px 2px 6px 0px #f5f5f4;
  border-radius: 10px 10px 0px 0px;
  min-height: 100vh;
  padding: 10px 12px;
  
}
.cash {
  background-image: url("../../public/image/record-bg@2x.png");
  background-size: 100% 160px;
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 32px;
  margin-bottom: 5px;
}
.num {
  font-size: 26px;
  font-weight: 500;
  color: #724404;
}
input{
  background-color: transparent;
  color: #724404;
  width: 50px;
}
.btn {
  background: #ca9a5b;
  border-radius: 45px;
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  margin-top: 10px;
}
.title {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  margin-bottom: 10px;
}
.item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 15px;
  font-weight: 400;
  color: #333333;
  margin-bottom: 5px;
  background: #ffffff;
  border-radius: 4px;
  padding: 10px 12px;
  margin-top: 10px;
  border-radius: 6px;
}
.item div {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}
.item div:nth-child(2){
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.item div:nth-child(1) p:nth-child(2) {
  font-size: 13px;
  font-weight: 400;
  color: #999999;
  margin-top: 7px;
}
.item div:nth-child(2) p:nth-child(2) {
  font-size: 13px;
  font-weight: 400;
  color: #fe4c0f;
}
</style>