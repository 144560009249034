<template>
  <div>
    <div class="give">
      <img src="../../public/image/bg-give.png" alt="" />
    </div>
    <div class="list">
      <div class="item">
        <img src="../../public/image/coupon.png" />
        <div class="price">抵用券</div>
        <div class="line"></div>
        <div class="title">会员指定20元以下任意饮品</div>
        <div class="details">有效期：周一至周日10:30-22:00</div>
        <div class="btn">赠送</div>
      </div>
      <div class="item">
        <img src="../../public/image/coupon.png" />
        <div class="price">抵用券</div>
        <div class="line"></div>
        <div class="title">满50减5元</div>
        <div class="details">有效期：周一至周日10:30-22:00</div>
        <div class="btn">赠送</div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
 
  data() {
    return {
     
    };
  },
};
</script>
<style scoped>

.give > img {
  width: 100%;
  height: 107px;
}
.list {
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  padding: 0 12px;
  padding-top: 10px;
  margin-top: -20px;
  height: 300px;
  background-color: #fff;
  position: absolute;
}
.item {
  position: relative;
  margin-top: 7px;
}

.item img {
  width: 100%;
  height: 100px;
}

.price {
  width: 30px;
  font-size: 18px;
  font-weight: 600;
  color: #fe4c0f;
  position: absolute;
  top: 13px;
  left: 27px;
}

.line {
  width: 1px;
  height: 86px;
  border-left: 1px dashed #ffb26a;
  position: absolute;
  top: 6px;
  left: 75px;
}

.title {
  width: 174px;
  height: 24px;
  font-size: 17px;

  font-weight: 600;
  color: #323233;
  line-height: 24px;
  position: absolute;
  top: 20px;
  left: 90px;
}

.details {
  width: 196px;
  height: 17px;
  font-size: 12px;
  font-weight: 400;
  color: #686868;
  line-height: 17px;
  position: absolute;
  top: 70px;
  left: 90px;
}

.btn {
  width: 60px;
  height: 30px;
  background: linear-gradient(90deg, #ff8301 0%, #fe4d10 100%);
  border-radius: 30px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  font-size: 13px;
  position: absolute;
  top: 33px;
  right: 10px;
}
</style>